export const Colors = {
  yellow: "#E7CF48",
  lightlightBlue: "#D8EEF6",
  lightBlue: "#00A0D6",
  darkBlue: "#035072",
  green: "#379298",
  orange: "#F0A14B",
  brightOrange: "#f98d01",
  red: "#dc3545",
  violet: "#8A2BE2",
  lightGreen: "#B2D3C2",
  lightGray: "#f1f1f1",
  lightBlue2: "#d7eaf4",
  lightlightBlue2: "#f2fbff",
  blue: "#029ed2",
  gray: "#dfdfdf",
  darkGray: "#d1d1d1",
  darkGray2: "#8b8b8b",
  purple: "#4570af",
  darkPurple: "#4550af",
  gold: "#F0BD59",
  lightPurple: "#BDB8DB",
  lightBlueGreen: "#8CD4C8",
  newLightGray: "#cdcdcd",
  newGray: "#6A6D72",
  newBlue: "#D2E3F1",
  newDarkGray: "#BFC2C9",
  newLightColor: "#E9EAED",
};
