import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Input from "../../../../../common/components/Input";
import SelectInput from "../../../../../common/components/SelectInput";
import { COUNTRIES } from "../../../../../common/constants/countries";
import { useRequestsContext } from "../../../../../common/hooks/requestHook";
import { useNavigate } from "react-router-dom";
import Loading from "../../../../../common/components/Loading";
import { createContact } from "../../../../../API/repositories/contact";
import translationManager from "../../../../../API/translationManager";
import { Colors } from "@/common/colors/colors";

const Wrapper = styled.div`
  margin: 0px 20px 20px 20px;
`;

const CreateForm = () => {
  const fullNameRef = useRef();
  const emailRef = useRef();
  const phoneNumberRef = useRef();
  const phoneNumberForCourierRef = useRef();
  const cityRef = useRef();
  const streetRef = useRef();
  const postalCodeRef = useRef();

  const [country, setCountry] = useState(null);
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();
  const navigate = useNavigate();

  const [translation, setTranslation] = useState({
    fullName: "Full name",
    country: "Country",
    phone: "Phone Number",
    save: "Save",
    city: "City",
    street: "Street",
    postalCode: "Postal Code",
    additionalInfo: "Additional Info",
    create: "Create Contact",
    phoneNumberForCourier: "Phone number for courier",
  });

  const translate = async () => {
    const translations = Object();

    await Promise.all(
      Object.entries(translation).map(async ([key, value]) => {
        const word = await translationManager.getTranslation(value);
        translations[key] = word;
      })
    );

    setTranslation(() => translations);
  };

  useEffect(() => {
    translate();
  }, []);

  const hadnleSaveConsultantContact = async (e) => {
    e.preventDefault();

    if (country) {
      const payload = Object();

      payload.full_name = fullNameRef.current.value;
      payload.orginal_phone_number = phoneNumberRef.current.value;
      payload.phone_number_for_courier = phoneNumberForCourierRef.current.value;
      payload.email = emailRef.current.value;
      payload.country = country.value;
      payload.city = cityRef.current.value;
      payload.postal_code = postalCodeRef.current.value;
      payload.street = streetRef.current.value;

      const response = await makeRequest(createContact.bind(null, payload));

      if (response.data) {
        navigate(`/consultant/contact/${response.data}`);
      } else {
        console.error(response.error);
      }
    } else {
    }
  };

  return (
    <Wrapper>
      <h3 style={{ marginBottom: "30px", color: Colors.darkBlue }}>
        {translation.create}
      </h3>
      <form onSubmit={(e) => hadnleSaveConsultantContact(e)}>
        <Input
          inputRef={fullNameRef}
          name={translation.fullName}
          required={true}
          width={230}
          color={Colors.darkBlue}
        />
        <Input
          inputRef={emailRef}
          type="email"
          name="E-mail"
          required={true}
          width={230}
          color={Colors.darkBlue}
        />
        <Input
          inputRef={phoneNumberRef}
          name={translation.phone}
          required={true}
          width={230}
          color={Colors.darkBlue}
        />
        <Input
          inputRef={phoneNumberForCourierRef}
          name={translation.phoneNumberForCourier}
          width={230}
          color={Colors.darkBlue}
        />
        <SelectInput
          name={translation.country}
          options={COUNTRIES}
          width={230}
          setSelected={setCountry}
          selected={country}
          color={Colors.darkBlue}
        />
        <Input
          inputRef={cityRef}
          name={translation.city}
          required={true}
          width={230}
          color={Colors.darkBlue}
        />
        <Input
          inputRef={streetRef}
          name={translation.street}
          required={true}
          width={230}
          color={Colors.darkBlue}
        />
        <Input
          inputRef={postalCodeRef}
          name={translation.postalCode}
          required={true}
          width={230}
          color={Colors.darkBlue}
        />
        <button
          className="btn btn-warning"
          type="submit"
          style={{ float: "right", margin: "10px", width: "100px" }}
        >
          {translation.save}
        </button>
      </form>
      {hasUnfilledRequest(createContact) && <Loading />}
    </Wrapper>
  );
};

export default CreateForm;
