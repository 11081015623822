import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { deleteQueueGroup } from "../../../../../API/repositories/queueGroups";
import Loading from "../../../../../common/components/Loading";
import PopUp from "../../../../../common/components/PopUp";
import { useRequestsContext } from "../../../../../common/hooks/requestHook";
import AddGroupView from "./AddGroupView";
import Table from "../../../../../common/components/Table";

const QueueGroups = ({
  queueGroups,
  users,
  handleGetQueueGroup,
  addMessage,
}) => {
  const [showAddGroup, setShowAddGroup] = useState(false);
  const [showId, setShowId] = useState(null);
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const showEdit = (e, id) => {
    e.preventDefault();
    setShowId(id);
    setShowAddGroup(true);
  };

  const hadnleDeleteQueueGroup = async (e, id) => {
    const response = await makeRequest(deleteQueueGroup.bind(null, id));
    if (response.data) {
      handleGetQueueGroup();
    }
  };

  const handleCreateQueueGroup = (e) => {
    e.preventDefault();
    setShowId(null);
    setShowAddGroup((prev) => !prev);
  };

  const QueueGroupsTr = styled.tr`
    position: relative;
  `;

  const QueueGroupsConsultants = styled.td`
    display: block;
    max-width: 400px;
  `;

  const QueueGroupsActions = styled.td`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
  `;

  const QueueGroupAction = styled.i`
    font-size: 14px;
    cursor: pointer;
    color: ${(props) => props.color || "black"};
  `;

  const headers = [
    "no",
    "Consultant",
    "Point from",
    "Point to",
    "Min length",
    "Max length",
    "Actions",
  ];
  const raws = useMemo(() => {
    if (queueGroups) {
      return queueGroups
        .sort((a, b) => a.point_from - b.point_from)
        .map((data, index) => (
          <QueueGroupsTr key={index}>
            <td>{index + 1}</td>

            <QueueGroupsConsultants>
              {users
                .filter((user) => data.consultants.includes(user.value))
                .map((userOption) => userOption.label)
                .join(", ")}
            </QueueGroupsConsultants>

            <td>{data.point_from}</td>

            <td>{data.point_to}</td>

            <td>{data.min_length}</td>

            <td>{data.max_length}</td>

            <QueueGroupsActions>
              <QueueGroupAction
                className="fa fa-edit"
                onClick={(e) => showEdit(e, data._id)}
              />

              <QueueGroupAction
                className="fa fa-trash"
                color="red"
                onClick={(e) => hadnleDeleteQueueGroup(e, data._id)}
              />
            </QueueGroupsActions>
          </QueueGroupsTr>
        ));
    }
  }, [users]);

  return (
    <div>
      {hasUnfilledRequest(deleteQueueGroup) && <Loading />}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0px 0px 20px 0px",
        }}
      >
        <h3>
          <u>Queue groups:</u>
        </h3>

        <button
          onClick={(e) => handleCreateQueueGroup(e)}
          className="btn btn-primary"
        >
          add
        </button>
      </div>

      <div>
        {queueGroups && (
          <Table
            className="styled-table styled-table-2"
            headers={headers}
            raws={raws}
          />
        )}
      </div>

      {showAddGroup ? (
        <PopUp setShow={setShowAddGroup}>
          <AddGroupView
            close={() => setShowAddGroup(false)}
            handleGetQueueGroup={handleGetQueueGroup}
            id={showId}
            users={users}
            addMessage={addMessage}
          />
        </PopUp>
      ) : (
        ""
      )}
    </div>
  );
};

export default QueueGroups;
