import moment from "moment";

export const formatDate = (date, format) => {
  return moment(date).format(format);
};

export const formatToDateTamplate = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

export const formatToDateTimeFormaterTemplate = (date) => {
  return moment(date).format("YYYY-MM-DDTHH:mm");
};
