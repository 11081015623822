export const MANUAL_CHECK_CONFIG = {
  Hungary: {
    link: 'https://ceginfo.hu/',
  },
  Croatia :{
    link: 'https://hr.kompass.com/en/searchCompanies?',
    query: 'text=',
  },
  Lithuania: {
    link: 'https://fsaskaita.lt/imones/paieska?',
    query: 'search=',
  },
  Slovakia: {
    link: 'https://www.finstat.sk/',
    query: '/',
  },
  Czech: {
    link: 'https://rejstrik-firem.kurzy.cz/hledej/',
    query: '?s=',
  },
}
