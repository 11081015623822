export const AVAILABLE_ORDER_FILEDS = [
  { label: "Full Name", value: { label: "full_name", type: "string" } },
  { label: "Phone Number", value: { label: "phone_number", type: "string" } },
  {
    label: "Orginal Phone Number",
    value: { label: "orginal_phone_number", type: "string" },
  },
  { label: "Email", value: { label: "email", type: "string" } },
  { label: "Cod Value", value: { label: "cod_value", type: "number" } },
  { label: "Order Items", value: { label: "order_items", type: "string" } },
  { label: "Status", value: { label: "status", type: "string" } },
  { label: "Product", value: { label: "product", type: "string" } },
  { label: "Market", value: { label: "market", type: "string" } },
  { label: "Confirmed by", value: { label: "confirmed_by", type: "string" } },
  {
    label: "Contact attemps",
    value: { label: "contact_attempts_queue_count", type: "string" },
  },
  {
    label: "Shipping status",
    value: { label: "shipping_status", type: "string" },
  },
  { label: "Waybill", value: { label: "waybill_number", type: "string" } },
  {
    label: "Confrimation date",
    value: { label: "confirmation_date", type: "string" },
  },
  { label: "Created date", value: { label: "created_at", type: "date" } },
  {
    label: "Suggested date",
    value: { label: "suggested_delivery_date", type: "date" },
  },
  {
    label: "Lms sent at",
    value: {
      label: "lms_sent_at",
      type: "date",
    },
  },
  { label: "Street", value: { label: "street", type: "string" } },
  { label: "Country", value: { label: "country", type: "string" } },
  { label: "Postal code", value: { label: "postal_code", type: "string" } },
  { label: "City", value: { label: "city", type: "string" } },
  { label: "UTM Campaign", value: { label: "utm_campaign", type: "string" } },
  { label: "UTM medium", value: { label: "utm_medium", type: "string" } },
  { label: "UTM term", value: { label: "utm_term", type: "string" } },
  { label: "UTM content", value: { label: "utm_content", type: "string" } },
  { label: "UTM source", value: { label: "utm_source", type: "string" } },
  {
    label: "Delivered at",
    value: { label: "status_delivered_at", type: "string" },
  },
  {
    label: "Delivered no payment at",
    value: { label: "status_delivered_no_payment_at", type: "string" },
  },
  {
    label: "Payment",
    value: { label: "payment", type: "string" },
  },
  {
    label: "Refund date",
    value: { label: "status_refunded_date", type: "string"},
  },
  {
    label: "Claim paid date",
    value: { label: "claim_paid_date", type: "string" },
  }
];
