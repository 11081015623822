export const HEADERS = [
  "No",
  "Email",
  "Market",
  "Type",
  "Entity name",
  "Sold date",
  "Total gross",
  "Invoice status",
  "Actions",
];

export const ACCEPTED_HEADERS = [
  "No",
  "Email",
  "Market",
  "Type",
  "Invoice number",
  "Entity name",
  "Issue date",
  "Sold date",
  "Total gross",
  "Status",
  "Actions",
];
