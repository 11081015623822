import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../../common/colors/colors";
import workingTime from "../../../images/icons/icons8-work-time-64.png";
import breakTime from "../../../images/icons/icons8-coffee-break-96.png";

const DateStyle = styled.h4`
  color: ${Colors.darkBlue};
  display: flex;
  gap: 20px;
`;

const InfoWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const DayInfomration = ({ workingSeconds, breakSeconds }) => {
  const formatToWorking = (seconds) => {
    const hours = parseInt(seconds / 60 / 60);
    const minutes = parseInt((seconds / 60) % 60);
    const restSeconds = parseInt(seconds % 60);

    return (
      <h3 style={{ color: Colors.darkBlue }}>
        {hours < 10 ? "0" + hours : hours}:
        {minutes < 10 ? "0" + minutes : minutes}:
        {restSeconds < 10 ? "0" + restSeconds : restSeconds}
      </h3>
    );
  };

  return (
    <DateStyle>
      <InfoWrapper>
        <img src={workingTime} width={40} height={40} />
        {formatToWorking(workingSeconds)}
      </InfoWrapper>
      <InfoWrapper>
        <img src={breakTime} width={40} height={40} />
        {formatToWorking(breakSeconds)}
      </InfoWrapper>
    </DateStyle>
  );
};

export default DayInfomration;
