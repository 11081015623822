import LoginPage from "@/pages/login/Login";
import ConsultantDashboardView from "@/ConsultantDashboardView";
import ConsultantOrders from "@/pages/consultant/ConsultantOrders";
import ConsultantFaq from "@/pages/consultant/consultantFaq/ConsultantFaq";
import ConsultantTickets from "@/pages/consultant/consultantTickets/ConsultantTickets";
import ConsultantSalary from "@/pages/consultant/ConsultantSalary";
import ConsultantTeamCurScore from "@/pages/consultant/ConsultantTeamCurScore";
import ConsultantTeamLastResult from "@/pages/consultant/ConsultantTeamLastResult";
import ConsultantStatistics from "@/pages/consultant/ConsultantStatistics";
import ConsultantContact from "@/pages/consultant/ConsultantContact";
import ConsultantContactOutbound from "@/pages/consultant/ConsultantContactOutbound";
import { CallCRMApi } from "../contexts/callingApiContext";
import ConsultantCalling from "@/pages/consultant/ConsultantCalling";
import ConsultantContactInbound from "@/pages/consultant/ConsultantContactInbound";
import ConsultantHours from "@/pages/consultant/consultantHours/ConsultantHours";
import AdminDashboardView from "@/AdminDashboardView";
import CCHours from "@/pages/admin/ccHours/CCHours";
import CCPayment from "@/pages/admin/ccPayment/CCPayment";
import CCPaymentKDR from "@/pages/admin/ccPaymentKDR/CCPaymentKDR";
import Dashboard from "@/pages/admin/dashboard/Dashboard";
import Tickets from "@/pages/admin/tickets/Tickets";
import ContactsFilterTable from "@/pages/admin/contactFilters/ContactsFilterTable";
import OrderFilterTable from "@/pages/admin/orderFitlerTable/OrderFilterTable";
import Contact from "@/pages/admin/Contact";
import Order from "@/pages/admin/order/Order";
import EffectiveReport from "@/pages/admin/EffectiveReport";
import ContactsRoad from "@/pages/admin/ContactsRoad";
import UTMReport from "@/pages/admin/utmReport/UTMReport";
import DailyMarket from "@/pages/admin/DailyMarket";
import NotPickUpCalls from "@/pages/admin/notPickUpCalls/NotPickUpCalls";
import DailyReport from "@/pages/admin/DailyReport";
import PaymentReport from "@/pages/admin/PaymentReport";
import GradeReport from "@/pages/admin/GradeReport";
import MarketNew from "@/components/admin/settings/market/MarketNew";
import BlackboardList from "@/components/admin/settings/blackboard/BlackboardList";
import FaqList from "@/components/admin/settings/faq/FaqList";
import Dictionary from "@/components/admin/settings/dictionary/Dictionary";
import MarketList from "@/components/admin/settings/market/MarketList";
import MarketShow from "@/components/admin/settings/market/MarketShow";
import ProductNew from "@/components/admin/settings/product/ProductNew";
import ProductList from "@/components/admin/settings/product/ProductList";
import ProductShow from "@/components/admin/settings/product/ProductShow";
import UserNew from "@/components/admin/settings/user/components/userForm/UserForm";
import UserList from "@/components/admin/settings/user/UserList";
import SentOrdersReport from "@/pages/admin/sentOrderReport/SentOrdersReport";
import QueueNew from "@/components/admin/settings/queue/QueueNew";
import QueueList from "@/components/admin/settings/queue/QueueList";
import QueueShow from "@/components/admin/settings/queue/QueueShow";
import MailSearch from "@/pages/admin/MailSearch";
import MoreInfoReport from "@/pages/admin/MoreInfoReport";
import Realtimedashboard from "@/pages/admin/realtimeDashboard/Realtimedashboard";
import VoipWorkTime from "@/pages/admin/voipWorkTime/VoipWorkTime";
import VoipStatistcs from "@/pages/admin/voipStatistics/VoipStatistcs";
import SMSSearch from "@/pages/admin/smsSearch/SMSSearch";
import SMSCreate from "@/pages/admin/smsCreate/SMSCreate";
import ClaimsSearch from "@/pages/admin/claimSearch/ClaimsSearch";
import ClaimNotEnded from "@/pages/admin/claimNotEnded/ClaimNotEnded";
import Invoices from "@/pages/admin/Invoices";
import ConsultantDashboard from "@/pages/consultant/ConsultantDashboard";
import ConsultantListeningCalls from "@/pages/consultant/consultantListening/ConsultantListeningCalls";
import ConsultantManagerListeningCalls from "@/pages/consultant/consultantManagerListeningCalls/ConsultantManagerListeningCalls";
import ListeningCalls from "@/pages/admin/listeningCalls/ListeningCalls";
import HoursReport from "@/pages/admin/hoursReport/HoursReport";
import DocumentsList from "@/components/admin/settings/documents/DocumentsList";
import ConsultantDrafts from "@/pages/consultant/consultantDrafts/ConsultantDrafts";
import VoipArchive from "@/pages/admin/voipArchive/VoipArchive";
import ListeningGeneratorList from "@/components/admin/settings/listeningGenerator/ListeningGeneratorList";
import ConsultantManagerScoringListening from "@/pages/consultant/consultantManagerScoringListening/ConsultantManagerScoringListening";
import ListeningScores from "@/pages/admin/listeningScores/ListeningScores";
import TestList from "@/components/admin/settings/test/TestList";
import MailTemplate from "@/pages/admin/MailTemplate";
import MailCategory from "@/pages/admin/mailCategory/MailCategory";
import MailTest from "@/pages/admin/MailTest";
import ConsultantTests from "@/pages/consultant/ConsultantTests";
import ClaimsReport from "@/pages/admin/claimsReport/ClaimsReport";
import ConsultantGamification from "@/pages/consultant/ConsultantGamification";
import CCGamification from "@/pages/admin/ccGamification/CCGamification";
import CallHistoryCsv from "@/pages/admin/callHistoryCsv/CallHistoryCsv";
import CallingSearch from "@/pages/admin/callingSearch/CallingSearch";
import PayoutSlip from "@/pages/admin/payoutSlip/PayoutSlip";
import InvoicesReport from "@/pages/admin/invoicesReport/InvoicesReport";
import CostPerLead from "@/pages/admin/costPerLeadReport/CostPerLead";
import MetaAccountsList from "@/components/admin/settings/meta/MetaAccountsList";
import Gamification from "@/components/admin/settings/gamification/Gamification";
import GamificationNew from "@/pages/admin/gamificationNew/GamificationNew";
import ValidInvoices from "@/pages/admin/validInvoices/ValidInvoices";
import Languages from "@/components/admin/settings/languages/Languages";
import ValidInvoicesReport from "@/pages/admin/validInvoicesReport/ValidInvoicesReport";
import InvoiceTemplates from "@/components/admin/settings/invoiceTemplates/InvoiceTemplates";
import SpeechToText from "@/pages/admin/speechToText/SpeechToText";
import Keys from "@/components/admin/settings/keys/Keys";
import Companies from "@/components/admin/settings/companies/Companies";
import InvoiceConfig from "@/components/admin/settings/invoiceConfig/InvoiceConfig";
import ServerRefresh from "@/pages/admin/serverRefresh/ServerRefresh";
import ConsultantProfile from "@/pages/consultant/consultantProfile/ConsultantProfile";
import LangPairClaimReport from "@/pages/admin/langPairClaimReport/LangPairClaimReport";
import LostPackagesReport from "@/pages/admin/lostPackagesReport/LostPackagesReport";
import SuspicioinReport from "@/pages/admin/suspicionReport/SuspicioinReport";
import ProductCPLReport from "@/pages/admin/productCPLReport/ProductCPLReport";
import HeadCcManagerScoringCalls from "@/pages/admin/headCcManagerScoringCalls/HeadCcManagerScoringCalls";
import HeadCcManagerScoringListening from "@/pages/admin/headCcManagerScoringListening/headCcManagerScoringListening";
import Department from "@/components/admin/settings/department/Department";
import SupportResignReport from "@/pages/admin/supportResignReport/SupportResignReport";
import PaymentProductCPLReport from "@/pages/admin/paymentProductCPLReport/PaymentProductCPLReport";
import Bonus from "@/pages/admin/bonus/Bonus";
import RecruitmentPage from "@/pages/admin/recruitmentPage/RecruitmentPage";
import CallCenterPosition from "@/pages/admin/callCenterPosition/CallCenterPosition";
import Recruitment from "@/pages/admin/recruitment/Recruitment";
import RecruitmentManager from "@/pages/consultant/recruitmentManager/RecruitmentManager";
import ConsultantCallingVonage from "@/pages/consultant/ConsultantCallingVonage";
import { CallCRMApiVoipVonage } from "../contexts/callingApiContextVonage";
import RecruitmentMailCategory from "@/pages/admin/recruitmentMailCategory/RecruitmentMailCategory";
import DynamicMailContent from "@/components/admin/settings/dynamicMailContent/DynamicMailContent";
import OrderLangStatsReport from "@/pages/admin/OrderLangStatsReport/OrderLangStatsReport";
import File from "@/pages/admin/file/File";
import DynamicMailAttachment from "@/components/admin/settings/dynamicMailAttachment/DynamicMailAttachment";
import Promotion from "@/pages/admin/promotion/Promotion";
import CCManagerHours from "@/pages/consultant/ccManagerHours/CCManagerHours";
import DailyReportManager from "@/pages/consultant/dailyReportManager/DailyReportManager";

const routes = [
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/consultant/dashboard",
    element: (
      <ConsultantDashboardView>
        {" "}
        <ConsultantDashboard />{" "}
      </ConsultantDashboardView>
    ),
  },
  {
    path: "/consultant/orders",
    element: (
      <ConsultantDashboardView>
        {" "}
        <ConsultantOrders />{" "}
      </ConsultantDashboardView>
    ),
  },
  {
    path: "/consultant/profile",
    element: (
      <ConsultantDashboardView>
        <ConsultantProfile />
      </ConsultantDashboardView>
    ),
  },
  {
    path: "/consultant/listening",
    element: (
      <ConsultantDashboardView>
        <ConsultantListeningCalls />
      </ConsultantDashboardView>
    ),
  },
  {
    path: "/consultant/faq",
    element: (
      <ConsultantDashboardView>
        {" "}
        <ConsultantFaq />{" "}
      </ConsultantDashboardView>
    ),
  },
  {
    path: "/consultant/tickets",
    element: (
      <ConsultantDashboardView>
        {" "}
        <ConsultantTickets />{" "}
      </ConsultantDashboardView>
    ),
  },
  {
    path: "/consultant/salary",
    element: (
      <ConsultantDashboardView>
        {" "}
        <ConsultantSalary />{" "}
      </ConsultantDashboardView>
    ),
  },
  {
    path: "/consultant/team-leader/grade",
    element: (
      <ConsultantDashboardView>
        {" "}
        <ConsultantTeamCurScore />{" "}
      </ConsultantDashboardView>
    ),
  },
  {
    path: "/consultant/team-leader/suspicion",
    element: (
      <ConsultantDashboardView>
        {" "}
        <SuspicioinReport />{" "}
      </ConsultantDashboardView>
    ),
  },
  {
    path: "/consultant/team-leader/hours",
    element: (
      <ConsultantDashboardView>
        <CCManagerHours />
      </ConsultantDashboardView>
    )
  },
  {
    path: "/consultant/team-leader/recruitment",
    element: (
      <ConsultantDashboardView>
        <RecruitmentManager />
      </ConsultantDashboardView>
    ),
  },
  {
    path: "/consultant/team-leader/listening",
    element: (
      <ConsultantDashboardView>
        {" "}
        <ConsultantManagerListeningCalls />{" "}
      </ConsultantDashboardView>
    ),
  },
  {
    path: "/consultant/team-leader/listening-scoring",
    element: (
      <ConsultantDashboardView>
        {" "}
        <ConsultantManagerScoringListening />{" "}
      </ConsultantDashboardView>
    ),
  },
  {
    path: "/consultant/team-leader/result",
    element: (
      <ConsultantDashboardView>
        {" "}
        <ConsultantTeamLastResult />{" "}
      </ConsultantDashboardView>
    ),
  },
  {
    path: "/consultant/team-leader/daily-report",
    element: (
      <ConsultantDashboardView>
        <DailyReportManager />
      </ConsultantDashboardView>
    ),
  },
  {
    path: "/consultant/statistics",
    element: (
      <ConsultantDashboardView>
        <ConsultantStatistics />
      </ConsultantDashboardView>
    ),
  },
  {
    path: "/consultant/contact/:id",
    element: (
      <ConsultantDashboardView>
        <ConsultantContact />
      </ConsultantDashboardView>
    ),
  },
  {
    path: "/consultant/drafts",
    element: (
      <ConsultantDashboardView>
        <ConsultantDrafts />
      </ConsultantDashboardView>
    ),
  },
  {
    path: "/consultant/tests",
    element: (
      <ConsultantDashboardView>
        <ConsultantTests />
      </ConsultantDashboardView>
    ),
  },
  {
    path: "/consultant/gamification",
    element: (
      <ConsultantDashboardView>
        <ConsultantGamification />
      </ConsultantDashboardView>
    ),
  },
  {
    path: "/consultant/contact/outbound/:id",
    element: <ConsultantContactOutbound />,
  },
  {
    path: "/consultant/calling",
    element: (
      <CallCRMApi>
        <ConsultantCalling />
      </CallCRMApi>
    ),
  },
  {
    path: "/consultant/calling/vonage",
    element: (
      <CallCRMApiVoipVonage>
        <ConsultantCallingVonage />
      </CallCRMApiVoipVonage>
    ),
  },
  {
    path: "/consultant/contact/inbound/:id",
    element: (
      <ConsultantDashboardView>
        {" "}
        <ConsultantContactInbound />{" "}
      </ConsultantDashboardView>
    ),
  },
  {
    path: "/consultant/hours",
    element: (
      <ConsultantDashboardView>
        <ConsultantHours />
      </ConsultantDashboardView>
    ),
  },

  {
    path: "/cc/gamification",
    element: (
      <AdminDashboardView>
        <CCGamification />
      </AdminDashboardView>
    ),
  },
  {
    path: "/cc/gamification/new",
    element: (
      <AdminDashboardView>
        <GamificationNew />
      </AdminDashboardView>
    ),
  },
  {
    path: "/cc/payout-slip",
    element: (
      <AdminDashboardView>
        <PayoutSlip />
      </AdminDashboardView>
    ),
  },
  {
    path: "/cc/speech-to-text",
    element: (
      <AdminDashboardView>
        <SpeechToText />
      </AdminDashboardView>
    ),
  },
  {
    path: "/cc/recruitment",
    element: (
      <AdminDashboardView>
        <Recruitment />
      </AdminDashboardView>
    ),
  },
  {
    path: "/cc/hours",
    element: (
      <AdminDashboardView>
        <CCHours />
      </AdminDashboardView>
    ),
  },
  {
    path: "/cc/hours_report",
    element: (
      <AdminDashboardView>
        <HoursReport />
      </AdminDashboardView>
    ),
  },
  {
    path: "/cc/payment",
    element: (
      <AdminDashboardView>
        <CCPayment />
      </AdminDashboardView>
    ),
  },
  {
    path: "/cc/payment_kdr",
    element: (
      <AdminDashboardView>
        <CCPaymentKDR />
      </AdminDashboardView>
    ),
  },
  {
    path: "/mail/recruitment-mail",
    element: (
      <AdminDashboardView>
        <RecruitmentMailCategory />
      </AdminDashboardView>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <AdminDashboardView>
        <Dashboard />
      </AdminDashboardView>
    ),
  },
  {
    path: "/dashboard/tickets",
    element: (
      <AdminDashboardView>
        <Tickets />
      </AdminDashboardView>
    ),
  },
  {
    path: "/dashboard/contacts",
    element: (
      <AdminDashboardView>
        <ContactsFilterTable />
      </AdminDashboardView>
    ),
  },
  {
    path: "/dashboard/orders",
    element: (
      <AdminDashboardView>
        <OrderFilterTable />
      </AdminDashboardView>
    ),
  },
  {
    path: "/dashboard/contact/:id",
    element: (
      <AdminDashboardView>
        <Contact />
      </AdminDashboardView>
    ),
  },
  {
    path: "/dashboard/order/:id",
    element: (
      <AdminDashboardView>
        <Order />
      </AdminDashboardView>
    ),
  },
  {
    path: "/report/effective",
    element: (
      <AdminDashboardView>
        <EffectiveReport />
      </AdminDashboardView>
    ),
  },
  {
    path: "/report/lang-pair-claim",
    element: (
      <AdminDashboardView>
        <LangPairClaimReport />
      </AdminDashboardView>
    ),
  },
  {
    path: "/report/lost-packages",
    element: (
      <AdminDashboardView>
        <LostPackagesReport />
      </AdminDashboardView>
    ),
  },
  {
    path: "/report/contacts-road",
    element: (
      <AdminDashboardView>
        <ContactsRoad />
      </AdminDashboardView>
    ),
  },
  {
    path: "/report/cost-per-lead",
    element: (
      <AdminDashboardView>
        <CostPerLead />
      </AdminDashboardView>
    ),
  },
  {
    path: "/report/suspicion",
    element: (
      <AdminDashboardView>
        <SuspicioinReport />
      </AdminDashboardView>
    ),
  },
  {
    path: "/report/utm",
    element: (
      <AdminDashboardView>
        <UTMReport />
      </AdminDashboardView>
    ),
  },
  {
    path: "/report/sent-orders",
    element: (
      <AdminDashboardView>
        <SentOrdersReport />
      </AdminDashboardView>
    ),
  },
  {
    path: "/report/product-cpl",
    element: (
      <AdminDashboardView>
        <ProductCPLReport />
      </AdminDashboardView>
    ),
  },
  {
    path: "/report/payment-product-cpl",
    element: (
      <AdminDashboardView>
        <PaymentProductCPLReport />
      </AdminDashboardView>
    ),
  },
  {
    path: "/report/daily-market",
    element: (
      <AdminDashboardView>
        <DailyMarket />
      </AdminDashboardView>
    ),
  },
  {
    path: "/report/not-pick-up-calls",
    element: (
      <AdminDashboardView>
        <NotPickUpCalls />
      </AdminDashboardView>
    ),
  },

  {
    path: "/report/daily",
    element: (
      <AdminDashboardView>
        <DailyReport />
      </AdminDashboardView>
    ),
  },

  {
    path: "/report/payment",
    element: (
      <AdminDashboardView>
        <PaymentReport />
      </AdminDashboardView>
    ),
  },

  {
    path: "/report/resign",
    element: (
      <AdminDashboardView>
        <SupportResignReport />
      </AdminDashboardView>
    ),
  },

  {
    path: "/report/order-langs-stats",
    element: (
      <AdminDashboardView>
        <OrderLangStatsReport />
      </AdminDashboardView>
    )
  },
  {
    path: "/report/grade",
    element: (
      <AdminDashboardView>
        <GradeReport />
      </AdminDashboardView>
    ),
  },
  {
    path: "/report/consultant",
    element: (
      <AdminDashboardView>
        <CallHistoryCsv />
      </AdminDashboardView>
    ),
  },
  {
    path: "/settings/market/new",
    element: (
      <AdminDashboardView>
        <MarketNew />
      </AdminDashboardView>
    ),
  },
  {
    path: "/settings/server-refresh",
    element: (
      <AdminDashboardView>
        <ServerRefresh />
      </AdminDashboardView>
    ),
  },
  {
    path: "/settings/test/list",
    element: (
      <AdminDashboardView>
        <TestList />
      </AdminDashboardView>
    ),
  },
  {
    path: "/settings/drafts/list",
    element: (
      <AdminDashboardView>
        <DocumentsList />
      </AdminDashboardView>
    ),
  },
  {
    path: "/settings/blackboard/list",
    element: (
      <AdminDashboardView>
        <BlackboardList />
      </AdminDashboardView>
    ),
  },
  {
    path: "/settings/faq/list",
    element: (
      <AdminDashboardView>
        <FaqList />
      </AdminDashboardView>
    ),
  },
  {
    path: "/settings/dictionary",
    element: (
      <AdminDashboardView>
        <Dictionary />
      </AdminDashboardView>
    ),
  },
  {
    path: "/settings/gamification",
    element: (
      <AdminDashboardView>
        <Gamification />
      </AdminDashboardView>
    ),
  },
  {
    path: "/settings/keys",
    element: (
      <AdminDashboardView>
        <Keys />
      </AdminDashboardView>
    ),
  },
  {
    path: "/settings/companies",
    element: (
      <AdminDashboardView>
        <Companies />
      </AdminDashboardView>
    ),
  },
  {
    path: "/settings/department",
    element: (
      <AdminDashboardView>
        <Department />
      </AdminDashboardView>
    ),
  },
  {
    path: "/settings/invoice-config",
    element: (
      <AdminDashboardView>
        <InvoiceConfig />
      </AdminDashboardView>
    ),
  },
  {
    path: "/settings/listening-generator",
    element: (
      <AdminDashboardView>
        <ListeningGeneratorList />
      </AdminDashboardView>
    ),
  },
  {
    path: "/settings/meta",
    element: (
      <AdminDashboardView>
        <MetaAccountsList />
      </AdminDashboardView>
    ),
  },
  {
    path: "/settings/languages",
    element: (
      <AdminDashboardView>
        <Languages />
      </AdminDashboardView>
    ),
  },
  {
    path: "/settings/market/list",
    element: (
      <AdminDashboardView>
        <MarketList />
      </AdminDashboardView>
    ),
  },
  {
    path: "/settings/market/show/:id",
    element: (
      <AdminDashboardView>
        <MarketShow />
      </AdminDashboardView>
    ),
  },
  {
    path: "/settings/product/new",
    element: (
      <AdminDashboardView>
        <ProductNew />
      </AdminDashboardView>
    ),
  },
  {
    path: "/settings/product/list",
    element: (
      <AdminDashboardView>
        <ProductList />
      </AdminDashboardView>
    ),
  },
  {
    path: "/settings/product/show/:id",
    element: (
      <AdminDashboardView>
        <ProductShow />
      </AdminDashboardView>
    ),
  },

  {
    path: "/settings/queue/new",
    element: (
      <AdminDashboardView>
        <QueueNew />
      </AdminDashboardView>
    ),
  },
  {
    path: "/settings/queue/list",
    element: (
      <AdminDashboardView>
        <QueueList />
      </AdminDashboardView>
    ),
  },
  {
    path: "/settings/queue/show/:id",
    element: (
      <AdminDashboardView>
        <QueueShow />
      </AdminDashboardView>
    ),
  },

  {
    path: "/settings/dictionary/new",
    element: (
      <AdminDashboardView>
        <MarketNew />
      </AdminDashboardView>
    ),
  },
  {
    path: "/settings/promotion",
    element: (
      <AdminDashboardView>
        <Promotion />
      </AdminDashboardView>
    ),
  },
  {
    path: "/settings/dictionary/list",
    element: (
      <AdminDashboardView>
        <MarketList />
      </AdminDashboardView>
    ),
  },
  {
    path: "/settings/dictionary/show/:id",
    element: (
      <AdminDashboardView>
        <MarketShow />
      </AdminDashboardView>
    ),
  },

  {
    path: "/settings/user/new",
    element: (
      <AdminDashboardView>
        <UserNew />
      </AdminDashboardView>
    ),
  },
  {
    path: "/settings/user/list",
    element: (
      <AdminDashboardView>
        <UserList />
      </AdminDashboardView>
    ),
  },
  {
    path: "/settings/invoice-templates",
    element: (
      <AdminDashboardView>
        <InvoiceTemplates />
      </AdminDashboardView>
    ),
  },
  {
    path: "/settings/bonus",
    element: (
      <AdminDashboardView>
        <Bonus />
      </AdminDashboardView>
    ),
  },
  {
    path: "/settings/recruitment-page",
    element: (
      <AdminDashboardView>
        <RecruitmentPage />
      </AdminDashboardView>
    ),
  },
  {
    path: "/settings/call-center-position",
    element: (
      <AdminDashboardView>
        <CallCenterPosition />
      </AdminDashboardView>
    ),
  },
  {
    path: "/settings/dynamic-mail-content",
    element: (
      <AdminDashboardView>
        <DynamicMailContent />
      </AdminDashboardView>
    )
  },
  {
    path: "/settings/dynamic-mail-attachment",
    element: (
      <AdminDashboardView>
        <DynamicMailAttachment />
      </AdminDashboardView>
    )
  },
  {
    path: "/settings/files",
    element: (
      <AdminDashboardView>
        <File />
      </AdminDashboardView>
    ),
  },
  {
    path: "/mail/search",
    element: (
      <AdminDashboardView>
        <MailSearch />
      </AdminDashboardView>
    ),
  },
  {
    path: "/mail/template",
    element: (
      <AdminDashboardView>
        <MailTemplate />
      </AdminDashboardView>
    ),
  },
  {
    path: "/mail/category",
    element: (
      <AdminDashboardView>
        <MailCategory />
      </AdminDashboardView>
    ),
  },
  {
    path: "/mail/test",
    element: (
      <AdminDashboardView>
        <MailTest />
      </AdminDashboardView>
    ),
  },
  {
    path: "/mail/mails-check",
    element: (
      <AdminDashboardView>
        <MoreInfoReport />
      </AdminDashboardView>
    ),
  },

  {
    path: "/voip/realtime-dashboard",
    element: (
      <AdminDashboardView>
        <Realtimedashboard />
      </AdminDashboardView>
    ),
  },
  {
    path: "/voip/working-time",
    element: (
      <AdminDashboardView>
        <VoipWorkTime />
      </AdminDashboardView>
    ),
  },
  {
    path: "/voip/statistics",
    element: (
      <AdminDashboardView>
        <VoipStatistcs />
      </AdminDashboardView>
    ),
  },
  {
    path: "/voip/archive",
    element: (
      <AdminDashboardView>
        <VoipArchive />
      </AdminDashboardView>
    ),
  },
  {
    path: "/voip/listening",
    element: (
      <AdminDashboardView>
        <ListeningCalls />
      </AdminDashboardView>
    ),
  },
  {
    path: "/voip/listening-scores",
    element: (
      <AdminDashboardView>
        <ListeningScores />
      </AdminDashboardView>
    ),
  },
  {
    path: "/voip/calling/search",
    element: (
      <AdminDashboardView>
        <CallingSearch />
      </AdminDashboardView>
    ),
  },
  {
    path: "/voip/sms/search",
    element: (
      <AdminDashboardView>
        <SMSSearch />
      </AdminDashboardView>
    ),
  },
  {
    path: "/voip/sms/create",
    element: (
      <AdminDashboardView>
        <SMSCreate />
      </AdminDashboardView>
    ),
  },
  {
    path: "/voip/listening-marks",
    element: (
      <AdminDashboardView>
        {" "}
        <HeadCcManagerScoringCalls />{" "}
      </AdminDashboardView>
    ),
  },
  {
    path: "/voip/listening-scoring",
    element: (
      <AdminDashboardView>
        <HeadCcManagerScoringListening />
      </AdminDashboardView>
    ),
  },
  {
    path: "/hr/claim/search",
    element: (
      <AdminDashboardView>
        <ClaimsSearch />
      </AdminDashboardView>
    ),
  },

  {
    path: "/hr/claim/not_ended_claims",
    element: (
      <AdminDashboardView>
        <ClaimNotEnded />
      </AdminDashboardView>
    ),
  },
  {
    path: "/hr/invoices",
    element: (
      <AdminDashboardView>
        <Invoices />
      </AdminDashboardView>
    ),
  },
  {
    path: "/valid-invoices",
    element: (
      <AdminDashboardView>
        <ValidInvoices />
      </AdminDashboardView>
    ),
  },
  {
    path: "/hr/claims-report",
    element: (
      <AdminDashboardView>
        <ClaimsReport />
      </AdminDashboardView>
    ),
  },
  {
    path: "/hr/invoices-report",
    element: (
      <AdminDashboardView>
        <InvoicesReport />
      </AdminDashboardView>
    ),
  },
  {
    path: "/valid-invoices-report",
    element: (
      <AdminDashboardView>
        <ValidInvoicesReport />
      </AdminDashboardView>
    ),
  },
];

export default routes;
