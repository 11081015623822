import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../common/colors/colors";
import ConsultantStatusButtons from "../../components/consultant/calling-vonage/ConsultantStatusButtons";
import DayInfomration from "../../components/consultant/calling-vonage/DayInfomration";
import { useNavigate } from "react-router-dom";
import DetailedStatistics from "../../components/consultant/calling-vonage/DetailedStatistics";
import GeneralStatistics from "../../components/consultant/calling-vonage/GeneralStatistics";
import { useRequestsContext } from "../../common/hooks/requestHook";
import FillLoading from "../../common/components/FillLoading";
import SearchingContact from "../../components/consultant/calling-vonage/SearchingContact";
import OutboundView from "../../components/consultant/calling-vonage/OutboundView";
import PopUp from "../../common/components/PopUp";
import ConsultantDecisionCalling from "../../components/consultant/calling-vonage/ConsultantDecisionCalling";
import WaitingPopup from "../../components/consultant/calling-vonage/WaitingPopup";
import Blackboard from "../../components/consultant/dashboard/panel/blackboard-info/Blackboard";
import translationManager from "../../API/translationManager";
import Conecting from "../../components/consultant/calling-vonage/Conecting";
import VoipError from "../../components/consultant/calling-vonage/VoipError";
import { getUserWorkingInformation } from "../../API/repositories/voip";
import ContactInbound from "../../components/consultant/calling-vonage/inbound/ContactInbound";
import SearchBar from "../../components/consultant/calling-vonage/search/SearchBar";
import { getConsultantBlackboardInfo } from "../../API/repositories/blackboardInfo";
import MessageQueue, { useMessageQueue } from "../../common/messageProvider";
import userManager from "../../API/userManager";
import TicketView from "@/components/consultant/infobar/ticket/components/ticketView/TicketView";
import { getConsultantTicketsAll } from "@/API/repositories/tickets";
import { sortTickets } from "@/components/consultant/infobar/ticket/helpers/sortTickets";
import { getAmountOfNotSeenMessages } from "@/components/consultant/infobar/ticket/helpers/getAmountOfNotSeenMessages";
import { getCallingDayConsultantStatistics } from "@/API/repositories/calling";
import {
  VonageClient,
  ClientConfig,
  ConfigRegion,
  LoggingLevel,
} from "@vonage/client-sdk";
import { CallCRMApiContextVonage } from "@/common/contexts/callingApiContextVonage";

const ErrorText = styled.h3`
  color: ${Colors.red};
  text-align: center;
`;

const Container = styled.div`
  min-width: 1040px;
  max-width: 1040px;
  height: fit-content;
  background-color: white;
  padding: 30px;
  border-radius: 15px;
`;

const Wrapper = styled.div`
  background-color: ${Colors.lightlightBlue};
  width: 100vw;
  padding-top: 30px;
  height: 100vh;
  overflow-y: scroll;
`;

const Flex = styled.div`
  display: flex;
  gap: 40px;
`;

const TopInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 30px;
`;

const DailyInformation = styled.div`
  margin: 40px 0px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  gap: 40px;
`;

const TOKEN_EXPIRED_TIME = 1000 * 60 * 5;

const ConsultantCallingVonage = () => {
  const [isPlivoConnected, setIsPlivoConnected] = useState(false);
  const [device, setDevice] = useState(null);
  const [call, setCall] = useState(null);
  const [session, setSession] = useState();

  const naviagte = useNavigate();
  const [hasMediaDevices, setMediaDevices] = useState(false);

  navigator.mediaDevices
    .getUserMedia({ audio: true })
    .then((result) => {
      setMediaDevices(true);
    })
    .catch((e) => setMediaDevices(false));

  const [data, setData] = useState();
  const [workingTime, setWorkingTime] = useState(false);
  const [workingSeconds, setWorkingSeconds] = useState(0);
  const [breakSeconds, setBreakSeconds] = useState(0);
  const [tickets, setTickets] = useState([]);
  const [oldTickets, setOldTickets] = useState([]);
  const [openTickets, setOpenTickets] = useState(false);
  const [blackboardInfo, setBlackboadrInfo] = useState();

  const { makeRequest } = useRequestsContext();
  const {
    sendStartCalling,
    currentContactOutbound,
    callingClientCredentials,
    setShowCallingDescription,
    showCallingDescription,
    waiting,
    sendLoginInformation,
    setWaiting,
    sendArchive,
    setCallingClientCredentials,
    setCurrentContactOutbound,
    isConnected,
    takeABreak,
    token,
    sendGetToken,
    voipError,
    setVoipError,
    setIsTalkingTicket,
    onUnmount,
    setHasPosibilityToCallNext,
    incoming,
    startRef,
    breakRef,
    isTalking,
    countWrapTime,
    setCountWrapTime,
    endCall,
  } = useContext(CallCRMApiContextVonage);

  const currentUserId = userManager.getUser().id;

  useEffect(() => onUnmount, []);

  useEffect(() => {
    if (device) return;
    try {
      const client = new VonageClient({
        loggingLevel: LoggingLevel.DEBUG,
        region: ConfigRegion.EU,
      });

      setDevice(() => client);
    } catch (e) {}
  }, [device]);

  useEffect(() => {
    if (!device && !token) return;

    device
      .createSession(token)
      .then((session) => {
        setSession(session);
        setIsPlivoConnected(() => true);
      })
      .catch((error) => console.log(error));
  }, [token]);

  useEffect(() => {
    if (!device) return;

    device.on("error", (twilioError, call) => {
      setIsPlivoConnected(() => false);
    });

    device.on("callInvite", (callId, from, channelTyp) => {
      device
        .answer(callId)
        .then(() => {
          setCall(() => callId);
          console.log("Success answering call.");
        })
        .catch((error) => {
          console.error("Error answering call: ", error);
        });
    });

    device.on("tokenWillExpire", () => {
      sendGetToken();
    });
  }, [device]);

  const handleWrappTimeClear = () => {
    sendArchive();
    setWorkingTime(() => false);
    setCountWrapTime(() => false);
    setCallingClientCredentials(() => null);
    setCurrentContactOutbound(() => null);

    breakRef.current = true;
    startRef.current = false;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      sendGetToken();
    }, TOKEN_EXPIRED_TIME);
    return () => clearInterval(interval);
  }, []);

  const handleBreak = (e) => {
    e && e.preventDefault();
    setWorkingTime(() => false);
    takeABreak();
    setHasPosibilityToCallNext(() => false);

    breakRef.current = true;
    startRef.current = false;
  };

  const handleStart = (e) => {
    e && e.preventDefault();
    setWorkingTime(() => true);
    sendStartCalling();
    setWaiting(() => true);

    startRef.current = true;
    breakRef.current = false;
  };
  const handleEnd = (e) => {
    e.preventDefault();
    setWorkingTime(() => false);
    startRef.current = false;
    breakRef.current = false;

    naviagte("/consultant/dashboard");
  };

  const handleInterval = () => {
    return setInterval(async () => {
      if (startRef.current) {
        setWorkingSeconds((prev) => prev + 1);
      }
      if (breakRef.current) {
        setBreakSeconds((prev) => prev + 1);
      }
    }, [1000]);
  };

  const loadData = async () => {
    const workingResponse = await makeRequest(getUserWorkingInformation);

    if (workingResponse.data) {
      setBreakSeconds(() => workingResponse.data.break_seconds);
      setWorkingSeconds(() => workingResponse.data.working_seconds);
    }
  };

  const loadTickets = async () => {
    const responseStats = await makeRequest(getCallingDayConsultantStatistics);
    if (responseStats.data) {
      setData(() => responseStats.data);
    }

    const response = await makeRequest(getConsultantTicketsAll);

    if (response.data?.activeTickets) {
      const { activeTickets } = response.data;
      const sortedActiveTickets = sortTickets(activeTickets);

      setTickets(() => sortedActiveTickets);

      if (tickets) {
        const previous = getAmountOfNotSeenMessages(tickets, currentUserId);
        const current = getAmountOfNotSeenMessages(
          activeTickets,
          currentUserId
        );

        if (previous < current) {
          addMessage("You have some new messages", "error");
        }
      }
    }

    if (response.data?.outdatedTickets) {
      const { outdatedTickets } = response.data;
      const sortedOutdatedTickets = sortTickets(outdatedTickets);

      setOldTickets(() => sortedOutdatedTickets);
    }
  };

  const handleEndCall = async () => {
    setIsTalkingTicket(() => false);
    endCall(call);
  };

  const handleTickets = (e) => {
    e.preventDefault();
    setOpenTickets(() => true);
  };

  const handleLoadBlackboardInfo = async () => {
    const response = await makeRequest(getConsultantBlackboardInfo);

    if (response.data) {
      const now = new Date().getTime();
      const newInfos = response.data.filter(
        (infos) => now - new Date(infos.created_at).getTime() < 20000
      );

      newInfos.forEach((info) => {
        const text =
          info.translations.find(
            (translation) => translation.lang === userManager.getUser().lang
          )?.text || info.message;

        addMessage(text, "info", 20000);
      });

      setBlackboadrInfo(() => response.data);
    }
  };

  useEffect(() => {
    loadTickets();
    handleLoadBlackboardInfo();
    const interval1 = setInterval(() => {
      loadTickets();
    }, 20000);

    const interval2 = setInterval(() => {
      handleLoadBlackboardInfo();
    }, 8000);

    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
    };
  }, []);

  useEffect(() => {
    const interval = handleInterval();
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    loadData();
  }, [showCallingDescription]);

  useEffect(() => {
    sendLoginInformation();
  }, []);

  const [translation, setTranslation] = useState({
    blackboard: "Informations",
    create_ticket: "Create ticket",
    message: "Message",
    type_message: "Type Message...",
    receiver: "Receiver",
    topic: "Topic",
    ticket_question: "Ticket Question",
    yourTickets: "Your Tickets",
    connectingToServer: "Connecting to server ...",
    connectingToCalling: "Connecting to calling ...",
    no: "No.",
    status: "Status",
    type: "Type",
    updated_by: "Last updated at",
    last_Updated_at: "Last updated at",
    last_message: "Last message",
    actions: "Actions",
    actions: "Actions",
    add: "Add",
    commentInfo: "Comment info",
    no_answer: "User that you are trying to call is not avaiable",
    calling: "Calling... ",
    errorText:
      "Please allow the microphone or check if the microphone works on your computer!!!",
    contact: "Contact",
    full_name: "Full name",
    country: "Country",
    email: "E-mail",
    postalCode: "Postal code",
    street: "Street",
    city: "City",
    additionalInfo: "Additional info",
    save: "Save",
    orders: "Orders",
    currency: "Currency",
    product: "Product",
    variants: "Variants",
    value: "Value",
    shipping_status: "Shipping status",
    edit: "Edit",
    mailError: "Error when sending",
    mailSuccess: "Your mail was sent",
    order: "Order",
    suggested_delivery: "Suggested delivery",
    changeDate: "Change date",
    price: "Price",
    short: "Short",
    variant: "Variant",
    action: "Action",
    confirmed: "Confirmed",
    shifts: "Shifts",
    resigned: "Resigned",
    mails: "Mails",
    general: "General",
    todays_contacts: "Today's contacts",
    todays_mails: "Today's mails",
    donwloaded_at: "Downloaded at",
    number: "Number",
    decision: "Decision",
    sent_at: "Sent at",
    placeholder: "email, name, number",
    there_is_no_client: "There is no client like that",
    new_ticket: "There is NEW TICKET!",
    tickets: "Tickets",
    file: "File",
    old_tickets: "Old tickets",
    phone_number_for_courier: "Phone for courier",
    curier: "Courier",
    changeCurier: "Change courier",
    timeline: "Timeline",
    invoice_status: "Invoice status by color",
    mail_history: "Mail history",
    shipping_history: "Shipping history",
    suspicion: "Suspicion",
  });

  const translate = async () => {
    const translations = Object();

    await Promise.all(
      Object.entries(translation).map(async ([key, value]) => {
        const word = await translationManager.getTranslation(value);
        translations[key] = word;
      })
    );

    setTranslation(() => translations);
  };

  useEffect(() => {
    translate();
  }, []);

  const { addMessage, messages: infos } = useMessageQueue();

  useEffect(() => {
    const onBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
    };

    window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, []);

  return (
    <Wrapper>
      <MessageQueue messages={infos} />
      <TopInfo>
        <DayInfomration
          workingSeconds={workingSeconds}
          breakSeconds={breakSeconds}
        />
        <Flex>
          <SearchBar translation={translation} handleEndCall={handleEndCall} />
          <ConsultantStatusButtons
            handleEnd={handleEnd}
            handleBreak={handleBreak}
            handleStart={handleStart}
            handleTickets={handleTickets}
            ticketsNumber={getAmountOfNotSeenMessages(tickets, currentUserId)}
          />
        </Flex>
      </TopInfo>
      <DailyInformation>
        {data && translation ? (
          <GeneralStatistics data={data} translation={translation} />
        ) : (
          <div style={{ maxWidth: "1000px", height: "200px" }}>
            <FillLoading />
          </div>
        )}
        <Container>
          {blackboardInfo && (
            <Blackboard translation={translation} data={blackboardInfo} />
          )}
        </Container>

        {data ? (
          <DetailedStatistics
            contactsData={[
              ...data.getTodayOutboundContacts,
              ...data.getTodayInboundContacts,
            ]}
            mailsData={data.findTodayMails}
            translation={translation}
          />
        ) : (
          <div
            style={{ minWidth: "1000px", maxWidth: "1000px", height: "500px" }}
          >
            <FillLoading />
          </div>
        )}
      </DailyInformation>
      {workingTime && callingClientCredentials && <SearchingContact />}
      {currentContactOutbound && (
        <OutboundView
          handleEndCall={handleEndCall}
          isTalking={isTalking}
          countWrapTime={countWrapTime}
          setCountWrapTime={setCountWrapTime}
          handleWrappTimeClear={handleWrappTimeClear}
        />
      )}
      {incoming && (
        <ContactInbound
          incoming={incoming}
          isTalking={isTalking}
          countWrapTime={countWrapTime}
          setCountWrapTime={setCountWrapTime}
          handleWrappTimeClear={handleWrappTimeClear}
          handleEndCall={handleEndCall}
        />
      )}
      {showCallingDescription && (
        <ConsultantDecisionCalling
          setShowCalling={setShowCallingDescription}
          handleBreak={handleBreak}
        />
      )}
      {waiting && <WaitingPopup />}
      {!hasMediaDevices && (
        <PopUp>
          <ErrorText>{translation.errorText}</ErrorText>
        </PopUp>
      )}
      {(!isConnected || !isPlivoConnected) && (
        <Conecting
          isConnected={isConnected}
          isPlivoConnected={isPlivoConnected}
          translation={translation}
        />
      )}
      {voipError && <VoipError close={setVoipError} />}
      {openTickets && (
        <TicketView
          isCallingTicket={true}
          handleEndCall={handleEndCall}
          setIsTicketViewOpen={setOpenTickets}
          translation={translation}
          tickets={tickets}
          oldTickets={oldTickets}
          loadData={loadTickets}
        />
      )}
    </Wrapper>
  );
};

export default ConsultantCallingVonage;
