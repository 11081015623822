export const DASHBOARD_VIEW_SETTINGS = [
  {
    label: "Calling stats",
    value: "calling_stats",
    access: "CALLING_STATS_DASHBOARD",
  },
  {
    label: "Consultant info",
    value: "consultant_info",
    access: "CONSULTANT_INFO_DASHBOARD",
  },
  {
    label: "Donut calling stats",
    value: "donute_calling_stats",
    access: "DONUT_CALLING_STATS_DASHBOARD",
  },
  {
    label: "Donut markets stats",
    value: "donute_market_stats",
    access: "DONUT_MARKET_STATS_DASHBOARD",
  },
  {
    label: "Hours consultant",
    value: "hours_consultant",
    access: "HOURS_CONSULTANT_DASHBOARD",
  },
  {
    label: "Archives",
    value: "archives",
    access: "ARCHIVES_DASHBOARD",
  },
  {
    label: "Mail stats",
    value: "mail_stats",
    access: "MAIL_STATS_DASHBOARD",
  },
  {
    label: "Wppk confirmed",
    value: "wppk_confirmed",
    access: "WPPK_CONFIRMED_DASHBOARD",
  },
  {
    label: "Wppk delivered",
    value: "wppk_delivered",
    access: "WPPK_DELIVERED_DASHBOARD",
  },
  {
    label: "Deliverability product",
    value: "delivery_market",
    access: "DELIVERY_MARKET_DASHBOARD",
  },
  {
    label: "Deliverability resign product",
    value: "delivery_resign_market",
    access: "DELIVERY_MARKET_DASHBOARD",
  },
  {
    label: "Deliverability consultant",
    value: "delivery_consultant",
    access: "DELIVERY_CONSULTANT_DASHBOARD",
  },
  {
    label: "Contact weights",
    value: "contact_weights",
    access: "CONTACT_WEIGHTS_DASHBOARD",
  },
  {
    label: "Leads",
    value: "leads",
    access: "LEADS_DASHBOARD",
  },
  {
    label: "Paid leads",
    value: "paid_leads",
    access: "LEADS_DASHBOARD",
  },
  {
    label: "Sent orders",
    value: "sent_orders",
    access: "SENT_ORDERS_DASHBOARD",
  },
  {
    label: "Wppk delivered utm",
    value: "wppk_delivered_utm",
    access: "WPPK_DELIVERED_UTM_DASHBOARD",
  },
  {
    label: "Wppk confirmed product",
    value: "wppk_confirmed_market",
    access: "WPPK_CONFIRMED_MARKET_DASHBOARD",
  },
  {
    label: "Wppk delivered product",
    value: "wppk_delivered_market",
    access: "WPPK_DELIVERED_MARKET_DASHBOARD",
  },
  {
    label: "Invoices",
    value: "invoices",
    access: "INVOICES_DASHBOARD",
  },
  {
    label: "Claims",
    value: "claims",
    access: "CLAIMS_DASHBOARD",
  },
  {
    label: "Current Leads",
    value: "queue_leads",
    access: "CURRENT_LEADS_DASHBOARD",
  },
  {
    label: "Active tickets",
    value: "active_tickets",
    access: "COMMON",
  },
  {
    label: "Cost per lead (Netto)",
    value: "cpl_stats",
    access: "CPL_STATS_DASHBOARD",
  },
  {
    label: "Calling Daily WPPK",
    value: "calling_daily_wppk",
    access: "CALLING_DAILY_STATS_DASHBOARD",
  },
  {
    label: "Daily Usage",
    value: "daily_usage",
    access: "DAILY_USAGE_QUEUE",
  },
];

export const DASHBOARD_REFRESH_SETTINGS = {
  label: "Refresh time",
  value: "refresh_time_seconds",
  access: "COMMON",
};

export const DASHBOARD_OLD_TICKETS_SETTINGS = {
  label: "Show old tickets",
  value: "show_old_tickets",
  access: "COMMON",
};

export const DASHBOARD_OLD_TICKETS_LIMIT_SETTINGS = {
  label: "Old tickets limit",
  value: "tickets_limit",
  access: "COMMON",
};

export const DASHBOARD_NOTIFICATIONS = {
  label: "Notifications",
  value: "notifications",
  access: "COMMON",
};
