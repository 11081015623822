import { formatDate } from "@/common/functions/dateFormater";
import { calculateTotalPrice } from "./calculateTotalPrice";
import { getStatusLabel } from "./getStatusLabel";
import { ActionIcon, LimitedWidthCell } from "../ValidInvoicesTable.styled";
import { Colors } from "@/common/colors/colors";

export const getRaws = (tickets, setSelectedTicket, markets, isRestricted) =>
  tickets.map((ticket, i) => (
    <tr key={`${ticket.created_at}_${ticket.valid_invoice.invoice_type}`}>
      <td>{i + 1}</td>
      <td>{ticket._contact.email}</td>
      <td>{markets.find(m => m.short === ticket._contact.country)?.name}</td>
      <td>{ticket.valid_invoice.invoice_type}</td>
      <LimitedWidthCell>
        {ticket.valid_invoice.company_name ||
          ticket.valid_invoice.full_name ||
          "----"}
      </LimitedWidthCell>
      <td>
        {formatDate(
          ticket._order.shipping?.status_delivered_at ||
            ticket._order.shipping?.status_delivered_no_payment_at,
          "YYYY-MM-DD"
        )}
      </td>
      <td>
        {calculateTotalPrice(ticket._order.order_items)}{" "}
        {ticket._order.currency}
      </td>
      <td>{getStatusLabel(ticket.status)}</td>
      <td>
        {!isRestricted && (
          <ActionIcon
            color={Colors.green}
            onClick={() => setSelectedTicket(() => ticket)}
            className="fa fa-check-to-slot"
          />
        )}
      </td>
    </tr>
  ));
