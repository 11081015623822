import React, { useRef, useState, useEffect } from "react";
import userManager from "@/API/userManager";
import { formatDate } from "@/common/functions/dateFormater";
import { getUserById } from "../../helpers/getUserById";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import {
  ChatWrapper,
  TranslationWrapper,
  TranslationIcon,
  NoChatData,
  ChatList,
  MessageBox,
  MessageBoxMessage,
  MessageBoxMessageSender,
  MessageBoxOriginal,
  MessageBoxTime,
  FileBox,
  FileTitle,
  StatusChangeWrapper,
  StatusChange,
  ExclamationIconWrapper,
  MessageBoxSenderWrapper,
  SeenWrapper,
  StatusChangeTime,
  SendingMessageWrapper,
  MessageSenderInfoWrapper,
} from "./Chat.styled";
import { useRequestsContext } from "@/common/hooks/requestHook";
import { getSortedTicketData } from "./helpers/getSortedTicketData";
import { getMessagePosition } from "./helpers/getMessagePostition";
import { getMessageBackgroundColor } from "./helpers/getMessageBackgroundColor";
import { getMessageText } from "./helpers/getMessageText";
import { getSeenMessage } from "./helpers/getSeenMessage";
import { getFileById } from "@/API/repositories/storedDocument";
import RichTextViewer from "@/common/components/richTextViewer/RichTextViewer";
import { Delta } from "quill/core";
import { getQuillDelta } from "./helpers/getQuillDelta";

const Chat = ({ selectedTicket, messageLoading }) => {
  const [showTranslation, setShowTranslation] = useState();
  const [loadingFiles, setLoadingFiles] = useState([]);

  const bottomRef = useRef();

  const currentUserId = userManager.getUser().id;

  const {
    commonData: { users },
  } = useCommonDataContext();
  const { makeRequest } = useRequestsContext();

  const handleDownloadFile = async (storedDataId) => {
    setLoadingFiles((prev) => [...prev, storedDataId]);

    const response = await makeRequest(getFileById.bind(null, storedDataId));

    if (response.data) {
      const url = window.URL.createObjectURL(
        new Blob([new Uint8Array(response.data.data.data).buffer])
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", response.data.name);
      document.body.appendChild(link);
      link.click();
    }

    setLoadingFiles((prev) => prev.filter((el) => el !== storedDataId));
  };

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [selectedTicket, messageLoading]);

  return (
    <ChatWrapper>
      {selectedTicket ? (
        <>
          <TranslationWrapper>
            <TranslationIcon
              onClick={() => setShowTranslation((prev) => !prev)}
              className="fa fa-language"
            />
          </TranslationWrapper>
          <ChatList>
            {selectedTicket &&
              getSortedTicketData(selectedTicket).map((element) => (
                <>
                  {element.type !== "status_change" ? (
                    <MessageBox
                      justifyContent={getMessagePosition(
                        element,
                        currentUserId
                      )}
                      key={element.created_at}
                    >
                      <MessageBoxMessage
                        backgroundColor={getMessageBackgroundColor(
                          element,
                          currentUserId
                        )}
                      >
                        <MessageBoxOriginal>
                          {element.id_in_bucket ? (
                            <FileBox
                              onClick={() => handleDownloadFile(element._id)}
                            >
                              <FileTitle>{element.id_in_bucket}</FileTitle>
                              <i className="fa fa-file" />
                            </FileBox>
                          ) : (
                            element.original ? getMessageText(
                              element,
                              currentUserId,
                              showTranslation
                            ) : (
                              <RichTextViewer
                                defaultValue = {getQuillDelta(element, currentUserId, showTranslation)}
                              />
                            )
                          )}
                        </MessageBoxOriginal>
                        <MessageSenderInfoWrapper>
                          <MessageBoxSenderWrapper>
                            <MessageBoxMessageSender>
                              {getUserById(
                                element.sender ||
                                  element.created_by ||
                                  selectedTicket.created_by,
                                users
                              )?.username || "System"}
                            </MessageBoxMessageSender>
                          </MessageBoxSenderWrapper>
                          <MessageBoxTime>
                            {formatDate(element.created_at, "YYYY/MM/DD HH:mm")}
                            {loadingFiles.includes(element._id) && (
                              <div>
                                <i className="fa-solid fa-spinner fa-spin" />
                              </div>
                            )}
                          </MessageBoxTime>
                        </MessageSenderInfoWrapper>
                      </MessageBoxMessage>
                    </MessageBox>
                  ) : (
                    <StatusChangeWrapper>
                      <StatusChange>
                        <ExclamationIconWrapper>
                          <i className="fa fa-exclamation" />
                        </ExclamationIconWrapper>
                        {element.original}
                      </StatusChange>
                      <StatusChangeTime>
                        {formatDate(element.created_at, "YYYY/MM/DD HH:mm")}
                      </StatusChangeTime>
                    </StatusChangeWrapper>
                  )}
                  {element.seen?.filter((userId) => userId !== currentUserId)
                    .length > 0 && (
                    <SeenWrapper>
                      {getSeenMessage(element.seen, users, currentUserId)}
                    </SeenWrapper>
                  )}
                </>
              ))}
            {messageLoading === selectedTicket && (
              <SeenWrapper>
                <SendingMessageWrapper>sending messsage</SendingMessageWrapper>
                <span>
                  <i className="fa fa-spinner fa-spin" />
                </span>
              </SeenWrapper>
            )}
          </ChatList>
        </>
      ) : (
        <NoChatData>No data</NoChatData>
      )}
      <div ref={bottomRef} />
    </ChatWrapper>
  );
};

export default Chat;
