import moment from "moment";
import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import {
  consultantHours,
  consultantHoursLastMonth,
  saveConsultantHours,
} from "@/API/repositories/consultantHours";
import translationManager from "@/API/translationManager";
import userManager from "@/API/userManager";
import { Colors } from "@/common/colors/colors";
import Input from "@/common/components/Input";
import PopUp from "@/common/components/PopUp";
import { useRequestsContext } from "@/common/hooks/requestHook";
import MessageQueue, { useMessageQueue } from "@/common/messageProvider";
import { PDF_TYPES } from "@/common/constants/pdfTypes";
import SelectInput from "@/common/components/SelectInput";
import Loading from "@/common/components/Loading";
import { useCommonDataContext } from "@/common/hooks/commonDataContext";
import { formatToDateTamplate } from "@/common/functions/dateFormater";
import * as MomentRange from "moment-range";
import { findMarketByContractType } from "@/common/functions/findMarketByContractType";
import Calendar from "./calendar/Calendar";
import { LAST_YEAR_MONTHS } from "@/common/constants/lastYearMonths";
import { saveConsultantPdfToAws } from "@/API/repositories/storedDocument";
import { getBonusesByUserId } from "@/API/repositories/bonus";
const momentRange = MomentRange.extendMoment(moment);

const Wrapper = styled.div`
  display: flex;
  margin: 30px;
  flex-direction: column;
  min-width: 98%;
  max-height: calc(100vh - 150px);
  align-items: center;
  gap: 30px;
  overflow-y: auto;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.p`
  color: ${Colors.darkBlue};
  font-weight: bold;
`;

const Info = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  align-items: center;
`;

const ConsultantHours = () => {
  const [freeDays, setFreeDays] = useState([]);
  const [workingHours, setWorkingHours] = useState();
  const [lastMonthHours, setLastMonthHours] = useState();
  const [isAddDocumentsOpen, setIsAddDocumentsOpen] = useState(false);
  const [bonuses, setBonuses] = useState();

  const user = userManager.getUser();

  const [translation, setTranslation] = useState({
    Month: "Month",
    "No.": "No.",
    Date: "Date",
    "Attendance at work": "Attendance at work",
    "Break during the day": "Break during the day",
    "Excused unpaid leave": "Excused unpaid leave",
    "Manager hours": "Manager hours",
    "Documents status": "Documents status",
    Actions: "Actions",
    "Absence without excuse": "Absence without excuse",
    hours_thead_one:
      'You have to fill cells in "reality working hours" columns at the end of each workday',
    hours_thead_two: "Markings",
    hours_thead_three: "Description of the markings",
    hours_thead_four: "Counted as",
    hours_thead_five: "Hours for day",
    one_description:
      'Each cell has to filled with character "1" or remain empty. "1" next to "8:30" means 8:30 to 9:00, "1" next to "19:30" means 19:30 to 20:00',
    two_description:
      'If you have a leave of absence, sick leave, leave on request enter the corresponding designation "h", "s", "u", "sl". Otherwise it will be marked as an unexcused absence. Excused absences - DOCUMENTS REQUIRED.:',
    three_description: 'If You want to take a break during the day. "/"',
    four_description: 'If You want to take an excused unpaid leave. "u"',
    five_description:
      'The number of working hours includes everything except unexcused absences. "n"',
    six_description: "If you are performing your managerial duties.:",
    one_short_description: "holiday leave (excused absences)	",
    two_short_description: "sick leave (excused absences)",
    three_short_description: "leave on request (excused absences)",
    four_short_description: "Break during the day",
    five_short_description: "Excused unpaid leave",
    six_short_description: "Absence without excuse (unexcused absences)",
    seven_short_description: "Managerial time",
    Save: "Save",
    Total: "Total",
    Monday: "Monday",
    Tuesday: "Tuesday",
    Wednesday: "Wednesday",
    Thursday: "Thursday",
    Friday: "Friday",
    Saturday: "Saturday",
    lastMonth: "Last month",
    currentMonth: "Current month",
    Documents: "Documents",
    "Add documents": "Add documents",
    "Add pdf": "Add pdf",
    "Add file type": "Add file type",
    From: "From",
    To: "To",
    "Leave your comment": "Leave your comment",
    Upload: "Upload",
    "Leave on request": "Leave on request",
    Holiday: "Holiday",
    "Sick leave": "Sick leave",
    "Special leave": "Special leave",
    "Vacation days left": "Vacation days left",
    Type: 'Type',
    Product: 'Product',
    Action: 'Action',
  });

  const [selectedPdfType, setSelectedPdfType] = useState(null);

  const {
    commonData: { markets, users },
    queryValues: { isLoading },
  } = useCommonDataContext();

  const fileRef = useRef();
  const fromDateRef = useRef();
  const toDateRef = useRef();
  const commentRef = useRef();

  const currentUserId = userManager.getUser().id;

  const getUserVacationDays = useMemo(() => {
    return users.find((user) => user._id === currentUserId)?.vacation_days || 0;
  }, [users]);

  const translate = async () => {
    const translations = Object();

    await Promise.all(
      Object.entries(translation).map(async ([key, value]) => {
        const word = await translationManager.getTranslation(value);
        translations[key] = word;
      })
    );

    setTranslation(() => translations);
  };

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();

  const renameFile = (originalFile, newName) => {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    });
  };

  const compareVacationDaysToSelectedDays = () => {
    const fromDate = new Date(fromDateRef.current.value);
    const toDate = new Date(toDateRef.current.value);

    const userMarket = findMarketByContractType(markets, user);

    const freeMarketDates = userMarket.free_days?.map((freeDay) =>
      formatToDateTamplate(freeDay.date)
    );

    const days = Array.from(momentRange.range(fromDate, toDate).by("day"));

    return days.reduce((acc, momentDate) => {
      const date = new Date(momentDate.toDate());

      if (
        date.getDay() === 6 ||
        date.getDay() === 0 ||
        freeMarketDates.includes(formatToDateTamplate(date))
      ) {
        return acc;
      }

      acc++;

      return acc;
    }, 0);
  };

  const handleUploadFile = async (e) => {
    e.preventDefault();

    if (hasUnfilledRequest(saveConsultantPdfToAws)) return;

    if (!selectedPdfType) {
      addMessage("Select pdf type", "error");

      return;
    }

    if (!fromDateRef.current?.value || !toDateRef.current?.value) {
      addMessage("Add all dates", "error");

      return;
    }

    if (
      new Date(fromDateRef.current.value).getTime() >
      new Date(toDateRef.current.value).getTime()
    ) {
      addMessage("Selected dates are wrong");

      return;
    }

    if (
      selectedPdfType.value === "paid_vacation" &&
      workingHours[0] &&
      compareVacationDaysToSelectedDays() > (getUserVacationDays || 0)
    ) {
      addMessage(`You have ${getUserVacationDays || 0} vacation days`, "error");

      return;
    }

    if (!commentRef.current?.value) {
      addMessage("Add comment", "error");

      return;
    }

    if (!fileRef.current?.files[0]) {
      addMessage("Add file", "error");

      return;
    }

    if (fileRef.current?.files[0].name.split(".").at(-1) !== "pdf") {
      addMessage("File must be in pdf format", "error");

      return;
    }

    const username = userManager.getUser()?.username;
    const name = username.split(".")[0];
    const surname = username.split(".")[1];

    const formData = new FormData();
    const file = renameFile(
      fileRef.current?.files[0],
      `${name}_${surname}_${fromDateRef.current.value}_${toDateRef.current.value}_${selectedPdfType.value}.pdf`
    );
    formData.append("file", file);

    const payload = {};

    payload.from = moment.utc(fromDateRef.current.value).format();
    payload.to = moment.utc(toDateRef.current.value).format();
    payload.comment = commentRef.current.value;
    payload.document_type = selectedPdfType.value;
    payload.days_amount = compareVacationDaysToSelectedDays();

    const response = await makeRequest(
      saveConsultantPdfToAws.bind(null, payload, formData)
    );

    if (response.data !== true) {
      const message = response.data || "Something went wrong!";
      return addMessage(message, "error");
    }

    addMessage("Saved", "success");

    await loadMonthHours();
    setIsAddDocumentsOpen(() => false);
    setSelectedPdfType(() => null);
  };

  const loadBonuses = async () => {
    const response = await makeRequest(getBonusesByUserId.bind(null, user.id));

    if (response?.data) {
      return setBonuses(() => response.data);
    }
  }


  const loadMonthHours = async () => {
    const response = await makeRequest(consultantHours);

    if (response.data) {
      setWorkingHours(() =>
        response.data.data.filter((hour) => hour.is_planned === false)
      );

      if (response.data.market[0]?.free_days?.length > 0)
        setFreeDays(() =>
          response.data.market[0].free_days.map((fd) => ({
            name: fd.name,
            value: fd.date,
          }))
        );
    }
  };

  const loadLastMonth = async () => {
    const response = await makeRequest(consultantHoursLastMonth);

    if (response.data) {
      setLastMonthHours(() => response.data);
    }
  };

  useLayoutEffect(() => {
    loadLastMonth();
    loadMonthHours();
    loadBonuses();
    translate();
  }, []);

  const { addMessage, removeMessage, messages } = useMessageQueue();

  return (
    <Wrapper>
      {(hasUnfilledRequest(
        consultantHours,
        consultantHoursLastMonth,
        saveConsultantPdfToAws,
        saveConsultantHours,
        getBonusesByUserId,
      ) ||
        isLoading) && <Loading />}
      <MessageQueue removeMessage={removeMessage} messages={messages} />
      {lastMonthHours && (
        <div className={`fade__in__animation`}>
          <table className="styled-table">
            <thead>
              <tr>
                <th>{translation["Month"]}</th>
                <th>{translation["Attendance at work"]}</th>
                <th>{translation["Holiday"]}</th>
                <th>{translation["Special leave"]}</th>
                <th>{translation["Sick leave"]}</th>
                <th>{translation["Leave on request"]}</th>
                <th>{translation["Break during the day"]}</th>
                <th>{translation["Excused unpaid leave"]}</th>
                <th>{translation["Absence without excuse"]}</th>
                {user.role === "CALL_CENTER_MANAGER" && (
                  <th>{translation["Manager hours"]}</th>
                )}
              </tr>
            </thead>
            <tbody className="queue">
              <tr>
                <td>
                  {moment().startOf("month").format("YYYY-MM-DD") +
                    "  -  " +
                    moment().endOf("month").format("YYYY-MM-DD")}
                </td>
                <td>
                  {workingHours?.reduce(
                    (prev, next) => prev + next.attendance_at_work,
                    0
                  )}
                  h
                </td>
                <td>
                  {workingHours?.reduce(
                    (prev, next) => prev + next.holiday_leave,
                    0
                  )}
                  h
                </td>
                <td>
                  {workingHours?.reduce(
                    (prev, next) => prev + (next.special_leave || 0),
                    0
                  )}
                  h
                </td>
                <td>
                  {lastMonthHours?.reduce(
                    (prev, next) => prev + next.sick_leave,
                    0
                  )}
                  h
                </td>
                <td>
                  {lastMonthHours?.reduce(
                    (prev, next) => prev + next.leave_on_reqests,
                    0
                  )}
                  h
                </td>
                <td>
                  {workingHours?.reduce(
                    (prev, next) => prev + next.break_during_the_day,
                    0
                  )}
                  h
                </td>
                <td>
                  {workingHours?.reduce(
                    (prev, next) => prev + next.excused_unpaid_leave,
                    0
                  )}
                  h
                </td>
                <td>
                  {workingHours?.reduce(
                    (prev, next) => prev + next.absence_without_excuse,
                    0
                  )}
                  h
                </td>
                {user.role === "CALL_CENTER_MANAGER" && (
                  <td>
                    {workingHours?.reduce(
                      (prev, next) => prev + next.manager_hours,
                      0
                    )}
                    h
                  </td>
                )}
              </tr>
              <tr>
                <td>
                  {moment()
                    .subtract("1", "month")
                    .startOf("month")
                    .format("YYYY-MM-DD") +
                    "  -  " +
                    moment()
                      .subtract("1", "month")
                      .endOf("month")
                      .format("YYYY-MM-DD")}
                </td>
                <td>
                  {lastMonthHours?.reduce(
                    (prev, next) => prev + next.attendance_at_work,
                    0
                  )}
                  h
                </td>
                <td>
                  {lastMonthHours?.reduce(
                    (prev, next) => prev + next.holiday_leave,
                    0
                  )}
                  h
                </td>
                <td>
                  {lastMonthHours?.reduce(
                    (prev, next) => prev + (next.special_leave || 0),
                    0
                  )}
                  h
                </td>
                <td>
                  {lastMonthHours?.reduce(
                    (prev, next) => prev + next.sick_leave,
                    0
                  )}
                  h
                </td>
                <td>
                  {lastMonthHours?.reduce(
                    (prev, next) => prev + next.leave_on_reqests,
                    0
                  )}
                  h
                </td>
                <td>
                  {lastMonthHours?.reduce(
                    (prev, next) => prev + next.break_during_the_day,
                    0
                  )}
                  h
                </td>
                <td>
                  {lastMonthHours?.reduce(
                    (prev, next) => prev + next.excused_unpaid_leave,
                    0
                  )}
                  h
                </td>
                <td>
                  {lastMonthHours?.reduce(
                    (prev, next) => prev + next.absence_without_excuse,
                    0
                  )}
                  h
                </td>
                {user.role === "CALL_CENTER_MANAGER" && (
                  <td>
                    {lastMonthHours?.reduce(
                      (prev, next) => prev + next.manager_hours,
                      0
                    )}
                    h
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
      )}
      <Info
        className={`fade__in__animation`}
        style={{ animationDelay: `100ms` }}
      >
        <Title>
          {translation["Vacation days left"]}: {getUserVacationDays || 0}
        </Title>
        <button
          onClick={() => setIsAddDocumentsOpen((prev) => !prev)}
          className="btn btn-warning animation-scale"
        >
          {translation["Add documents"]}{" "}
          <i className="fa-solid fa-file-circle-plus"></i>
        </button>
      </Info>
      {workingHours && translation && (
        <Calendar
          bonuses={bonuses}
          data={workingHours}
          month={LAST_YEAR_MONTHS[0].value}
          freeDays={freeDays}
          hanldeSearch={loadMonthHours}
          translation={translation}
          addMessage={addMessage}
        />
      )}
      {isAddDocumentsOpen && (
        <PopUp setShow={setIsAddDocumentsOpen}>
          <h4 style={{ color: Colors.darkBlue, marginBottom: "24px" }}>
            {translation["Add pdf"]}
          </h4>
          <Column>
            <SelectInput
              options={PDF_TYPES}
              showLabel={false}
              placeholder={`${translation["Add file type"]}`}
              selected={selectedPdfType}
              setSelected={setSelectedPdfType}
            />
            <SpaceBetween>
              <Input
                type="date"
                name={`${translation["From"]}`}
                inputRef={fromDateRef}
                width="90"
              />
              <Input
                type="date"
                name={`${translation["To"]}`}
                inputRef={toDateRef}
                width="30"
              />
            </SpaceBetween>
            <Input
              showLabel={false}
              placeholder={`${translation["Leave your comment"]}`}
              inputRef={commentRef}
            />
            <SpaceBetween style={{ marginLeft: "10px" }}>
              <input
                ref={fileRef}
                type="file"
                accept="application/pdf"
                style={{ width: "260px" }}
              />
              <button
                type="button"
                className="btn btn-warning"
                onClick={(e) => handleUploadFile(e)}
              >
                {translation["Upload"]}
              </button>
            </SpaceBetween>
          </Column>
        </PopUp>
      )}
    </Wrapper>
  );
};

export default ConsultantHours;
