import Claims from "@/components/admin/dashboard/claims/Claims";
import Invoices from "@/components/admin/dashboard/invoices/Invoices";
import QueueLeads from "../components/queueLeads/QueueLeads";
import CallingStats from "../components/callingStats/CallingStats";
import ConsultantInfo from "../components/consultantInfo/ConsultantInfo";
import DonuteCallingStats from "../components/donuteCallingStats/DonuteCallingStats";
import DonuteMarketStats from "../components/donuteMarketStats/DonuteMarketStats";
import HoursConsultant from "../components/hoursConsultant/HoursConsultant";
import Archives from "../components/archives/Archives";
import MailStats from "../components/mailStats/MailStats";
import WppkConfirmed from "../components/wppkConfirmed/WppkConfirmed";
import WPPKDelivered from "../components/wppkDelivered/WPPKDelivered";
import DeliveryMarket from "../components/deliveryMarket/DeliveryMarket";
import DeliveryConsultant from "../components/deliveryConsultant/DeliveryConsultant";
import ContactWeights from "../components/contactWeights/ContactWeights";
import Leads from "../components/leads/Leads";
import SentOrders from "../components/sentOrders/SentOrders";
import WPPKDeliveredUTM from "../components/wppkDeliveredUTM/WPPKDeliveredUTM";
import WppkConfirmedMarket from "../components/wppkConfirmeMarket/WppkConfirmedMarket";
import WPPKDeliveredMarket from "../components/wppkDeliveredMarket/WPPKDeliveredMarket";
import CPLStats from "../components/cplStats/CPLStats";
import CallingStatsWPPK from "../components/callingStatWppk/CallingStatsWPPK";
import DeliveryResignMarket from "../components/deliveryResignMarket/DeliveryResignMarket";
import DailyLeadUsage from "../components/dailyLeadUsage/DailyLeadUsage";
import ActiveTickets from "../components/activeTickets/ActiveTickets";
import PaidLeads from "../components/paidLeads/PaidLeads";

export const DASHBOARD_CONFIG = [
  { calling_stats: <CallingStats /> },
  { consultant_info: <ConsultantInfo /> },
  { donute_calling_stats: <DonuteCallingStats /> },
  { donute_market_stats: <DonuteMarketStats /> },
  { hours_consultant: <HoursConsultant /> },
  { archives: <Archives /> },
  { mail_stats: <MailStats /> },
  { wppk_confirmed: <WppkConfirmed /> },
  { wppk_delivered: <WPPKDelivered /> },
  { delivery_market: <DeliveryMarket /> },
  { delivery_resign_market: <DeliveryResignMarket /> },
  { delivery_consultant: <DeliveryConsultant /> },
  { contact_weights: <ContactWeights /> },
  { leads: <Leads /> },
  { paid_leads: <PaidLeads /> },
  { sent_orders: <SentOrders /> },
  { wppk_delivered_utm: <WPPKDeliveredUTM /> },
  { wppk_confirmed_market: <WppkConfirmedMarket /> },
  { wppk_delivered_market: <WPPKDeliveredMarket /> },
  { claims: <Claims /> },
  { invoices: <Invoices /> },
  { queue_leads: <QueueLeads /> },
  { cpl_stats: <CPLStats /> },
  { calling_daily_wppk: <CallingStatsWPPK /> },
  { daily_usage: <DailyLeadUsage /> },
  { active_tickets: <ActiveTickets /> },
];
