import React, { useEffect, useRef, useState } from 'react'
import Loading from '../Loading';
import { useRequestsContext } from '@/common/hooks/requestHook';
import { sendMailByMailType } from '@/API/repositories/mail';
import translationManager from '@/API/translationManager';
import { ButtonWrapper, CustomIcon } from './SupportMail.styled';
import PopUp from '../PopUp';
import SelectInput from '../SelectInput';
import { getDepartments } from '@/API/repositories/department';
import MessageQueue, { useMessageQueue } from '@/common/messageProvider';
import { Colors } from '@/common/colors/colors';
import CustomButtonSave from '../buttons/CustomButtonSave';
import { createGeneralQuestionTicket } from '@/API/repositories/tickets';
import RichTextEditor from '../richTextEditor/RichTextEditor';
import { handleExtractDelta } from '@/common/functions/handleExtractDelta';

const SupportMail = ({ order }) => {
  const [color, setColor] = useState("none");
  const [canSendMail, setCanSendMail] = useState(true);
  const [translation, setTranslation] = useState({
    sendMail: "Send Support Mail",
    mailError: "Error",
    mailSuccess: "Success",
  });
  const [showCreateQuestion, setShowCreateQuestion] = useState();
  const [supportDepartment, setSupportDepartment] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState();

  const { addMessage, removeMessage, messages } = useMessageQueue();
  const { hasUnfilledRequest, makeRequest } = useRequestsContext();

  const quillRef = useRef();

  const handleSubmitSupport = async (e) => {
    e && e.preventDefault();

    if (!quillRef.current.getText()?.trim()) {
      return addMessage("Please fill the message", "error");
    }
    
    setShowCreateQuestion(false);

    const formData = new FormData();

    formData.append("topic", selectedTopic.value);
    formData.append("quill_delta", JSON.stringify(handleExtractDelta(quillRef)));
    formData.append("order", order._id);
    formData.append("contact", order.contact);
    formData.append("reciver", supportDepartment?._id);

    const ticketResponse = await makeRequest(createGeneralQuestionTicket.bind(null, formData));

    if (!ticketResponse?.data) {
      setColor(() => "red");
      setTimeout(() => {
        setColor(() => Colors.yellow);
      }, 3000)
      return addMessage("Couldn't create a ticket", "error");
    }
    
    const mailResponse = await makeRequest(
      sendMailByMailType.bind(
        null,
        "SUPPORT_" + order._market.short.toUpperCase(),
        order._id
      )
    );
    setCanSendMail(false);
    setTimeout(() => {
      setCanSendMail(true);
    }, 10000)

    if (!mailResponse?.data) {
      setColor(() => "red");
      setTimeout(() => {
        setColor(() => "#ffca2c");
      }, 3000)
      return addMessage("Unable to send mail", "error");
    }

    setColor(() => "green");
    setTimeout(() => {
      setColor(() => "#ffca2c");
    }, 3000)
    addMessage("Success", "success")
  };

  const translate = async () => {
    const translations = Object();

    await Promise.all(
      Object.entries(translation).map(async ([key, value]) => {
        const word = await translationManager.getTranslation(value);
        translations[key] = word;
      })
    );

    setTranslation(() => translations);
  };

  const loadDepartments = async () => {
    const response = await makeRequest(getDepartments);

    if (!response?.data) {
      return addMessage("Loading error", "error");
    }

    setSupportDepartment(() => response.data.find((d) => d.name === "Support"));
   
  };

  const loadBaseData = () => {
    loadDepartments();
    translate();
  }

  useEffect(() => {
    loadBaseData();
  }, []);

  return (
    <div>
      <MessageQueue messages={messages} removeMessage={removeMessage} />
      {hasUnfilledRequest(sendMailByMailType, getDepartments, createGeneralQuestionTicket) && <Loading />}
      <button
        style={{
          backgroundColor: color,
          border: color,
          transform: ["green", "red"].includes(color)
            ? "scale(1.1)"
            : "scale(1)",
        }}
        className="btn btn-warning"
        type='button'
        onClick={() => setShowCreateQuestion(true)}
        disabled={!canSendMail}
      >
        <CustomIcon className="fa fa-envelope" />
        {color === "green"
          ? translation.mailSuccess
          : color === "red"
          ? translation.mailError
          : translation.sendMail}{" "}
      </button>
      {showCreateQuestion && (<PopUp setShow={setShowCreateQuestion}>
       <form onSubmit={handleSubmitSupport}>
        <SelectInput
          name="Topic"
          color={Colors.darkBlue}
          width={40}
          selectWidth={520}
          options={supportDepartment?.topics}
          selected={selectedTopic}
          setSelected={setSelectedTopic}
          required
          hideStar
        />
        <div style={{
          maxWidth: '600px'
        }}>
          <RichTextEditor ref={quillRef} />
        </div>
        <ButtonWrapper>
          <CustomButtonSave text="Send " />
        </ButtonWrapper>
       </form>
      </PopUp>)}
    </div>
  )
}

export default SupportMail
