const { default: userManager } = require("@/API/userManager");

export const ROLES = userManager.getAllRoles().map((role) => {
  return { label: role, value: role };
});

export const ACCESS = userManager.getAllAcesses().map((access) => {
  return { label: access, value: access };
});

export const CONSULTANT_ROLE = "CALL_CENTER_OPERATOR";

export const CONSULTANT_ACCESSES = ["CONSULTANT_COMMON", "COMMON"];

export const MANAGER_ROLE = "CALL_CENTER_MANAGER";
export const CONSULTNAT_ROLE = "CONSULTANT";

export const ROLE_HIDDEN_FIELDS = {
  ADMIN: [CONSULTNAT_ROLE],
  HEAD_CC_MANAGER: [CONSULTNAT_ROLE],
  FINANCE_ANALYST: [CONSULTNAT_ROLE],
  SUPPORT: [CONSULTNAT_ROLE],
  ANALYST: [CONSULTNAT_ROLE],
  CALL_CENTER_OPERATOR: [MANAGER_ROLE],
  CALL_CENTER_MANAGER: [],
};
