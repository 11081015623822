import Api from "../api";

import { errorHandler } from "../common";
const { tokenManager, axios: api } = Api;

export const getQueueGroups = (queueId) =>
  errorHandler(async () => {
    const data = await api.get(`/queue_group/queue/${queueId}`);
    return { data: data.data };
  });

export const getOneQueueGroup = (queueGroupId) =>
  errorHandler(async () => {
    const data = await api.get(`/queue_group/${queueGroupId}`);
    return { data: data.data };
  });

export const createQueueGroup = (queueGroup) =>
  errorHandler(async () => {
    const data = await api.post(`/queue_group`, {
      queue: queueGroup.queue,
      consultants: queueGroup.consultants,
      point_from: queueGroup.point_from,
      point_to: queueGroup.point_to,
      max_length: queueGroup.max_length,
      min_length: queueGroup.min_length,
      min_length: queueGroup.min_length,
      shouldnt_take_no_user_shift: queueGroup.shouldnt_take_no_user_shift,
    });
    return { data: data.data };
  });

export const updateQueueGroup = (queueId, queueGroup) =>
  errorHandler(async () => {
    const data = await api.patch(`/queue_group/${queueId}`, {
      consultants: queueGroup.consultants,
      point_from: queueGroup.point_from,
      point_to: queueGroup.point_to,
      max_length: queueGroup.max_length,
      min_length: queueGroup.min_length,
      min_length: queueGroup.min_length,
      shouldnt_take_no_user_shift: queueGroup.shouldnt_take_no_user_shift,
    });
    return { data: data.data };
  });

export const deleteQueueGroup = (queueGroup) =>
  errorHandler(async () => {
    const data = await api.delete(`/queue_group/${queueGroup}`);
    return { data: data.data };
  });
