import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getSearchedContactWithOrders } from "../../../API/repositories/contact";
import {
  changeDeliveryDate,
  markOrderAsClaim,
  markOrderAsResigned,
} from "../../../API/repositories/order";
import translationManager from "../../../API/translationManager";
import { Colors } from "../../colors/colors";
import { CLAIM_REASONS } from "../../constants/support";
import { PRIORITIES } from "../../constants/priorities";
import { useRequestsContext } from "../../hooks/requestHook";
import Input from "../Input";
import PopUp from "../PopUp";
import SelectInput from "../SelectInput";
import MessageQueue, { useMessageQueue } from "../../messageProvider";
import InvoiceTicket from "../invoiceTicket/InvoiceTicket";
import { getTrackingLink } from "../../functions/getTrackingLink";
import { BANK_ACCOUNT_COUNTRIES, IBAN_COUNTRIES } from "../../constants/claims";
import OrderWithDeliveryTable from "./components/orderWithDeliveryTable/OrderWithDeliveryTable";
import FastPayOrderTable from "./components/fastPayOrderTable/FastPayOrderTable";
import { refundOrder } from "@/API/repositories/cms";
import Loading from "../Loading";
import CustomButtonAdd from "../buttons/CustomButtonAdd";
import Resign from "../resign/Resign";

const SearchBox = styled.div`
  display: flex;
  align-items: center;
`;

const FiledNameWrapper = styled.h2`
  color: ${Colors.darkBlue};
  margin-right: 20px;
`;

const FiledWrapper = styled.h3`
  color: ${Colors.darkBlue};
  font-weight: normal;
  margin-right: 8px;
  margin-top: 4px;
`;

const MINIMUM_SEARCH_LENGTH = 3;

const AdminSearchContact = () => {
  const [showData, setShowData] = useState(null);
  const [edit, setEdit] = useState(null);
  const [editData, setEditData] = useState(null);
  const [resigned, setResigned] = useState(null);
  const [claim, setClaim] = useState(null);
  const [selectedClaimReason, setSelectedClaimReason] = useState(
    CLAIM_REASONS[0]
  );
  const [additionalCheckRefund, setAdditionalCheckRefund] = useState();
  const [selectedOrderPriority, setSelectedOrderPriority] = useState(
    PRIORITIES[0]
  );
  const [invoice, setInvoice] = useState();
  const [displayedContact, setDisplayedContact] = useState(null);

  const claimBankAccountInput = useRef();
  const claimFeedbacktInputRef = useRef();
  const claimIbanInputRef = useRef();

  const searchRef = useRef();
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();
  const navigate = useNavigate();
  const deliveryDateRef = useRef();
  const resignedCommentInputRef = useRef();

  const handleKeyUp = (e) => {
    if (e.key === "Enter") handleSearch(e);
  };

  const handleSearch = async (e) => {
    const searchData = searchRef.current.value;
    if (searchData.length < MINIMUM_SEARCH_LENGTH) {
      return;
    }
    setShowData(() => null);
    const response = await makeRequest(
      getSearchedContactWithOrders.bind(null, searchData)
    );

    if (response.data) {
      setShowData(() => response.data);
    }

    if (displayedContact) {
      setDisplayedContact(() =>
        response.data.find((d) => d._id === displayedContact._id)
      );
    }
  };

  const handleShowSearched = (e, _id) => {
    e.preventDefault();
    setShowData(() => null);
    setDisplayedContact(() => null);
    searchRef.current.value = "";
    navigate(`/dashboard/contact/${_id}`);
  };

  const handleShowSearchedOrders = (e, _id) => {
    e.preventDefault();
    setShowData(() => null);
    setDisplayedContact(() => null);
    searchRef.current.value = "";
    navigate(`/dashboard/order/${_id}`);
  };

  const handleResign = async (e, reason, commentManual) => {
    e.preventDefault();
    const payload = {};

    payload._id = resigned._id;
    payload.comment = reason;
    payload.comment_manual = commentManual;

    const response = await makeRequest(markOrderAsResigned.bind(null, payload));

    if (response.data) {
      await handleSearch();
      addMessage("Mail was queued", "success");
      setResigned(() => null);
      setShowData(() => null);
    } else {
      addMessage("Error", "Error");
    }
  };

  const [translation, setTranslation] = useState({
    placeholder: "Type email, name, phone number",
  });

  const translate = async () => {
    const translations = Object();
    translations.placeholder = await translationManager.getTranslation(
      "Type email, name, phone number"
    );
    setTranslation(() => translations);
  };

  const handleUpdateDeliveryDate = async (e) => {
    e.preventDefault();

    const response = await makeRequest(
      changeDeliveryDate.bind(null, editData._id, deliveryDateRef.current.value)
    );

    if (response.data) {
      await handleSearch();
      addMessage("Updated", "success");
      setEdit(() => false);
      setEditData(() => null);
    }
  };

  const handleClaim = async (e) => {
    e.preventDefault();

    if (IBAN_COUNTRIES.includes(displayedContact.country)) {
      const trimmedIban = claimIbanInputRef.current?.value?.replace(/\s/g, "");

      if (trimmedIban.slice(0, 2).toLowerCase() !== displayedContact.country) {
        return addMessage("Wrong iban prefix", "error");
      }

      let isError = false;
      if (displayedContact.country === "hr") {
        isError = trimmedIban?.length !== 21;
      } else {
        isError = trimmedIban?.length !== 24;
      }

      if (isError) {
        return addMessage("Wrong iban length", "error");
      }
    }

    if (
      displayedContact.country === "hu" &&
      claimBankAccountInput.current?.value?.length < 16
    ) {
      return addMessage("Wrong bank account length", "error");
    }

    const payload = {};

    payload.bank_account = claimBankAccountInput.current?.value;
    payload.iban = claimIbanInputRef.current?.value;
    payload.reason = selectedClaimReason.value;
    payload.feedback = claimFeedbacktInputRef.current.value;
    payload.priority = selectedOrderPriority.value;

    const response = await makeRequest(
      markOrderAsClaim.bind(null, claim, payload)
    );

    if (response.data) {
      addMessage("Mail was queued", "success");
      await handleSearch();
      setClaim(() => null);
    }
  };

  const getClaimMAX = (date) => {
    return moment(date).add("14", "days").format("YYYY-MM-DD");
  };

  const getDiffDate = (date) => {
    const maxClaimDate = moment(getClaimMAX(date));
    const currentDate = moment(date);

    return maxClaimDate.diff(currentDate, "days");
  };

  const getColor = (date) => {
    if (!date) return "black";

    const diff = getDiffDate(date);

    if (diff < 21) {
      return "green";
    }

    return "red";
  };

  const handleSendRefund = async (orderId) => {
    setAdditionalCheckRefund(() => orderId);
  };

  const sendRefund = async (orderId) => {
    const cmsResponse = await makeRequest(refundOrder.bind(null, orderId));

    if (!cmsResponse?.data) {
      return addMessage("Something went wrong", "error");
    }

    await handleSearch();
    return addMessage("Successfully refunded", "success");
  };

  const { addMessage, messages } = useMessageQueue();

  useEffect(() => {
    handleSearch();
    translate();
  }, [edit]);

  return (
    <SearchBox>
      {hasUnfilledRequest(refundOrder) && <Loading />}
      <MessageQueue messages={messages} />
      <input
        ref={searchRef}
        className="offInputStyle"
        type="text"
        style={{
          width: "400px",
          border: `1px solid ${Colors.darkBlue}`,
        }}
        onKeyUp={(e) => handleKeyUp(e)}
        placeholder={translation.placeholder}
      />
      <i
        className="fa fa-search animation-scale"
        onClick={(e) => handleSearch(e)}
        style={{
          cursor: "pointer",
          marginLeft: "-30px",
          color: Colors.darkBlue,
        }}
      ></i>
      {showData && (
        <PopUp setShow={setShowData}>
          <div style={{ marginTop: "40px" }}>
            <table className="styled-table">
              <thead>
                <tr>
                  <th>Full Name</th>
                  <th>Phone Number</th>
                  <th>Email</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody className="queue">
                {showData.map((data) => (
                  <tr>
                    <td>{data.full_name}</td>
                    <td>
                      {data.phone_number
                        ?.match(/.{1,3}/g)
                        ?.map((substring) => substring + " ")}
                    </td>
                    <td>{data.email}</td>
                    <td>
                      <i
                        className="fa fa-edit animation-scale"
                        style={{ cursor: "pointer", color: Colors.orange }}
                        onClick={(e) => {
                          setDisplayedContact(data);
                          setShowData(null);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </PopUp>
      )}
      {displayedContact && (
        <PopUp setShow={setDisplayedContact}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              border: `1px dashed ${Colors.darkBlue}`,
              padding: "20px",
              borderRadius: "15px",
              width: "fit-content",
            }}
          >
            <FiledNameWrapper>{displayedContact.full_name}: </FiledNameWrapper>
            <FiledWrapper> {displayedContact.country} </FiledWrapper>
            <FiledWrapper>
              {displayedContact.phone_number
                ?.match(/.{1,3}/g)
                ?.map((substring) => substring + " ")}
            </FiledWrapper>
            <FiledWrapper> {displayedContact.email}</FiledWrapper>
            <i
              className="fa fa-edit animation-scale"
              style={{
                cursor: "pointer",
                color: Colors.darkBlue,
                fontSize: "22px",
                marginLeft: "50px",
                marginTop: "5px",
              }}
              onClick={(e) => handleShowSearched(e, displayedContact._id)}
            />
          </div>
          <div style={{ marginTop: "60px" }}>
            <OrderWithDeliveryTable
              getColor={getColor}
              getClaimMAX={getClaimMAX}
              handleShowSearchedOrders={handleShowSearchedOrders}
              displayedContact={displayedContact}
              setClaim={setClaim}
              setResigned={setResigned}
              getTrackingLink={getTrackingLink}
              setEditData={setEditData}
              setEdit={setEdit}
              setInvoice={setInvoice}
            />
          </div>
          <div style={{ marginTop: "60px" }}>
            <FastPayOrderTable
              handleSendRefund={handleSendRefund}
              handleShowSearchedOrders={handleShowSearchedOrders}
              displayedContact={displayedContact}
              setInvoice={setInvoice}
            />
          </div>
          {invoice && (
            <InvoiceTicket
              handleSearch={handleSearch}
              market={invoice._market}
              addMessage={addMessage}
              setShowInvoiceTicket={setInvoice}
              orderId={invoice._id}
            />
          )}
          {edit && (
            <PopUp setShow={setEdit}>
              <form
                onSubmit={(e) => handleUpdateDeliveryDate(e)}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Input
                  inputRef={deliveryDateRef}
                  name="Delivery date"
                  width={120}
                  type="date"
                  required={true}
                />
                <button
                  className="btn btn-warning"
                  type="submit"
                  style={{ alignSelf: "center", margin: "20px 0px 0px 0px" }}
                >
                  Save
                </button>
              </form>
            </PopUp>
          )}
          {resigned && (
            <Resign setShow={setResigned} handleResign={handleResign} />
          )}
          {claim && (
            <PopUp setShow={setClaim}>
              <form
                onSubmit={(e) => handleClaim(e)}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  paddingBottom: "40px",
                }}
              >
                {BANK_ACCOUNT_COUNTRIES.includes(displayedContact.country) && (
                  <Input
                    name="Bank account"
                    inputRef={claimBankAccountInput}
                    width={140}
                    inputWidth={300}
                    type="text"
                    required={true}
                  />
                )}
                {IBAN_COUNTRIES.includes(displayedContact.country) && (
                  <Input
                    inputRef={claimIbanInputRef}
                    name="IBAN"
                    width={140}
                    inputWidth={300}
                    type="text"
                    required={true}
                  />
                )}
                <SelectInput
                  selected={selectedClaimReason}
                  options={CLAIM_REASONS}
                  setSelected={setSelectedClaimReason}
                  name="Reason"
                  width={140}
                  selectWidth={300}
                />
                <Input
                  inputRef={claimFeedbacktInputRef}
                  name="Feedback"
                  width={140}
                  inputWidth={300}
                  type="text"
                  required={true}
                />
                <SelectInput
                  name="Priority"
                  width={140}
                  selectWidth={300}
                  options={PRIORITIES}
                  selected={selectedOrderPriority}
                  setSelected={setSelectedOrderPriority}
                />
                <button
                  className="btn btn-warning"
                  type="submit"
                  style={{ alignSelf: "center", margin: "40px 0px 0px 0px" }}
                >
                  Claim
                </button>
              </form>
            </PopUp>
          )}
          {additionalCheckRefund && (
            <PopUp>
              Are you sure that order should be refunded?
              <div
                style={{
                  display: "flex",
                  gap: "32px",
                  justifyContent: "center",
                  marginTop: "24px",
                }}
              >
                <CustomButtonAdd
                  defaultText="Yes"
                  onClick={() => {
                    sendRefund(additionalCheckRefund);
                    setAdditionalCheckRefund(() => null);
                  }}
                  color={Colors.green}
                />
                <CustomButtonAdd
                  defaultText="No"
                  color={Colors.red}
                  onClick={() => {
                    setAdditionalCheckRefund(() => null);
                  }}
                />
              </div>
            </PopUp>
          )}
        </PopUp>
      )}
    </SearchBox>
  );
};

export default AdminSearchContact;
