import Icon from "@/common/components/Icon";
import { findMarketById } from "@/common/functions/findMarketById";
import { ActionsWrapper } from "../TemplateTable.styled";
import { downloadByBase64 } from "./downloadByBase64";
import { findProductById } from "@/common/functions/findProductById";

export const getRaws = (templates, markets, products, setSelectedTemplate) =>
  templates.map((template) => (
    <tr key={template._id}>
      <td>{template.filename}</td>
      <td>{findMarketById(template.market, markets)?.short.toUpperCase()}</td>
      <td>{findProductById(template.product, products)?.name}</td>
      <td>
        <embed src={template.base64 + "#toolbar=0"} height={240} width={300} />
      </td>
      <td>
        <ActionsWrapper>
          <Icon
            onClick={() => setSelectedTemplate(template)}
            name="fa fa-edit"
          />
          <Icon
            onClick={() => downloadByBase64(template.base64, template.filename)}
            name="fa fa-file-pdf"
          />
        </ActionsWrapper>
      </td>
    </tr>
  ));
