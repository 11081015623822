import React, { useEffect, useRef, useState } from "react";
import PopUp from "@/common/components/PopUp";
import { useRequestsContext } from "@/common/hooks/requestHook";
import {
  getCompanyDataByNumber,
  getCompanyDataByVat,
} from "@/API/repositories/invoices";
import Loading from "@/common/components/Loading";
import { createValidInvoiceTicket } from "@/API/repositories/tickets";
import { preventFn } from "@/common/functions/preventEvent";
import InvoiceNotSet from "./components/invoiceNotSet/InvoiceNotSet";
import { INVOICE_CREATION_TYPES } from "./contstants/invoices";
import InvoiceByVat from "./components/invoiceByVat/InvoiceByVat";
import InvioceByNumber from "./components/invoiceByNumber/InvioceByNumber";
import translationManager from "@/API/translationManager";
import OldWayCreate from "./components/oldWayCreate/OldWayCreate";
import PhysicalPerson from "./components/physicalPerson/PhysicalPerson";

const InvoiceTicket = ({
  setShowInvoiceTicket,
  orderId,
  addMessage,
  market,
  handleSearch = () => {},
}) => {
  const [invoiceCreateBy, setInvoiceCreateBy] = useState(
    INVOICE_CREATION_TYPES.VAT_ID
  );

  const companyNameRef = useRef();
  const vatRef = useRef();
  const companyIdRef = useRef();
  const addressRef = useRef();
  const commentRef = useRef();
  const streetRef = useRef();
  const postalCodeRef = useRef();
  const cityRef = useRef();

  const { makeRequest, hasUnfilledRequest } = useRequestsContext();

  const [translation, setTranslation] = useState({
    create_by_vat: "Create by company vat id",
    create_by_different_id: "Create by different company id",
    vat_id: "Vat id",
    company_country: "Company country",
    company_id: "Company id",
    add_comment: "Add comment...",
    maps: "go to maps",
    create_invoice: "Create invoice",
    check_company: "Check company",
    vat_id_check: "Go to vat id check",
    different_id_check: "Go to different company id check",
    address: "Address",
    company_name: "Company name",
    old_way: "Old way",
    company_street_and_number: "Company street and number",
    company_postal_code: "Company postal code",
    company_city: "Company city",
    find_in_google: "google search",
  });

  const translate = async () => {
    const translations = Object();
    await Promise.all(
      Object.entries(translation).map(async ([key, value]) => {
        const word = await translationManager.getTranslation(value);
        translations[key] = word;
      })
    );
    setTranslation(() => translations);
  };

  useEffect(() => {
    translate();
  }, []);

  const handleCreateValidInvoiceTicket = async (e) => {
    e && preventFn(e);

    const payload = {};

    payload.company_name = companyNameRef.current.value;
    payload.company_address = addressRef.current.value;
    payload.vat_number = vatRef.current?.value;
    payload.company_number = companyIdRef.current?.value;
    payload.additional_info = commentRef.current?.value;
    payload.city = cityRef.current.value.trim();
    payload.street = streetRef.current.value.trim();
    payload.postal_code = postalCodeRef.current.value.trim();

    if (payload.vat_number && !isValidAddress) {
      return addMessage("Not valid address data", "error");
    }

    if (payload.company_number && !isValidAddress) {
      payload.additional_info +=
        " Address data was not validated and has to be checked";
    }

    const response = await makeRequest(
      createValidInvoiceTicket.bind(null, orderId, payload)
    );

    if (!response.data) {
      return addMessage("Couldn't create invoice ticket", "error");
    }

    setShowInvoiceTicket(() => false);
    addMessage("Succesfully created invoice ticket", "success");
    handleSearch();
  };

  const handleManualCreation = async (fn) => {
    const success = await fn();

    if (!success) {
      return addMessage("Couldn't create invoice ticket", "error");
    }

    setShowInvoiceTicket(() => false);
    addMessage("Succesfully created invoice ticket", "success");
    handleSearch();
  };

  const removeSpaces = (str) => str?.replaceAll(" ", "");
  const trimmedAddress = removeSpaces(addressRef.current?.value);

  const isValidAddress =
    trimmedAddress?.includes(removeSpaces(cityRef.current?.value)) &&
    trimmedAddress?.includes(removeSpaces(streetRef.current?.value)) &&
    trimmedAddress?.includes(removeSpaces(postalCodeRef.current?.value));

  return (
    <PopUp padding="10px 20px 32px" setShow={setShowInvoiceTicket}>
      {hasUnfilledRequest(
        getCompanyDataByVat,
        getCompanyDataByNumber,
        createValidInvoiceTicket
      ) && <Loading />}
      {invoiceCreateBy === INVOICE_CREATION_TYPES.NOT_SET && (
        <InvoiceNotSet
          translation={translation}
          setInvoiceCreateBy={setInvoiceCreateBy}
        />
      )}
      {invoiceCreateBy === INVOICE_CREATION_TYPES.VAT_ID && (
        <InvoiceByVat
          addMessage={addMessage}
          vatRef={vatRef}
          addressRef={addressRef}
          companyNameRef={companyNameRef}
          handleCreateValidInvoiceTicket={handleCreateValidInvoiceTicket}
          setInvoiceCreateBy={setInvoiceCreateBy}
          commentRef={commentRef}
          streetRef={streetRef}
          cityRef={cityRef}
          postalCodeRef={postalCodeRef}
          translation={translation}
          orderMarket={market}
        />
      )}
      {invoiceCreateBy === INVOICE_CREATION_TYPES.COMPANY_ID && (
        <InvioceByNumber
          handleManualCreation={handleManualCreation}
          translation={translation}
          companyIdRef={companyIdRef}
          addressRef={addressRef}
          addMessage={addMessage}
          companyNameRef={companyNameRef}
          handleCreateValidInvoiceTicket={handleCreateValidInvoiceTicket}
          setInvoiceCreateBy={setInvoiceCreateBy}
          commentRef={commentRef}
          streetRef={streetRef}
          cityRef={cityRef}
          postalCodeRef={postalCodeRef}
          orderMarket={market}
          orderId={orderId}
        />
      )}
      {invoiceCreateBy === INVOICE_CREATION_TYPES.PHYSICAL_PERSON && (
        <PhysicalPerson
          orderId={orderId}
          addMessage={addMessage}
          setShowInvoiceTicket={setShowInvoiceTicket}
          handleSearch={handleSearch}
        />
      )}
      {invoiceCreateBy === INVOICE_CREATION_TYPES.OLD_WAY && (
        <OldWayCreate
          addMessage={addMessage}
          translation={translation}
          orderId={orderId}
          setShow={setShowInvoiceTicket}
        />
      )}
    </PopUp>
  );
};

export default InvoiceTicket;
