import React, { useState } from "react";
import {
  ActionIcon,
  ActionsWrapper,
  ContactAddIconWrapper,
  Hover,
  Label,
  LabelWrapper,
} from "../../ConversationGroup.styled";
import { Colors } from "@/common/colors/colors";
import {
  CALLING_TICKETS,
  NOT_ABLE_TO_CLOSE,
  OLD_STATUSES,
} from "../../constants/statuses";
import { useNavigate } from "react-router-dom";
import { useRequestsContext } from "@/common/hooks/requestHook";
import {
  markTicketAsClose,
  updateDocumentTicket,
} from "@/API/repositories/tickets";
import { getOneUser, updateUser } from "@/API/repositories/user";
import AddContact from "../addContact/AddContact";
import {
  getFileById,
  getRecordingById,
} from "@/API/repositories/storedDocument";
import MediaPlayer from "@/common/components/MediaPlayer";
import { getTranscriptionById } from "@/API/repositories/transcription";
import Transcriptions from "@/common/components/transcriptions/Transcriptions";
import MakeDecision from "@/common/components/makeDecision/MakeDecision";
import { getCallHistory } from "@/API/repositories/contact";
import CallHistoryTimeline from "@/common/components/callHistoryTimeline/CallHistoryTimeline";
import { copyLinkToClipboard } from "@/common/functions/copyLinkToClipboard";

const ConversationActoins = ({
  selectedTicket,
  handleCloseTicketView,
  loadData,
  setIsToCorrectOpen,
  setRecording,
  setShowlisteningObjections,
  setIsToDeclineOpen,
  renderOptimisticTicketUi,
  rerenderTickets,
  addMessage,
}) => {
  const [isAddContactOpen, setIsAddContactOpen] = useState();
  const [isAttachmentLoading, setIsAttachmentLoading] = useState();
  const [isAudioLoading, setIsAudioLoading] = useState();
  const [audio, setAudio] = useState();
  const [isTranscriptionLoading, setIsTranscriptionLoading] = useState();
  const [transcription, setTranscription] = useState();
  const [isDeciding, setIsDeciding] = useState();
  const [callHistoryTimeline, setCallHistoryTimeline] = useState();

  const navigate = useNavigate();
  const { makeRequest, hasUnfilledRequest } = useRequestsContext();

  const handleNavigateToContact = () => {
    handleCloseTicketView();
    navigate(`/dashboard/contact/${selectedTicket.contact}`);
  };

  const handleNavigateToOrder = () => {
    handleCloseTicketView();
    navigate(`/dashboard/order/${selectedTicket.order}`);
  };

  const handleNavigateToCandidate = () =>{
    handleCloseTicketView();
    navigate(`/cc/recruitment?candidate=${selectedTicket.notification.call_center_candidate}`);
  }

  const handleCloseTicket = async () => {
    renderOptimisticTicketUi();

    const response = await makeRequest(
      markTicketAsClose.bind(null, selectedTicket._id)
    );

    if (!response?.data) {
      addMessage("Couldn't close ticket", "error");
      rerenderTickets();
    }
  };

  const handleAcceptDocument = async () => {
    renderOptimisticTicketUi("accepted_pending");

    const currentUserResponse = await makeRequest(
      getOneUser.bind(null, selectedTicket.reciver || selectedTicket.created_by)
    );

    if (!currentUserResponse?.data) {
      addMessage("Something went wrong", "error");
      return rerenderTickets();
    }

    const currentUser = currentUserResponse.data;

    const payload = {};

    payload.ticket_status = "accepted_pending";

    if (selectedTicket.document.document_type === "paid_vacation") {
      const daysAmount = selectedTicket.document.days_amount;

      if (currentUser.vacation_days - daysAmount < 0) {
        addMessage("User doesn't have enough vacation days", "error");
        return rerenderTickets();
      }

      currentUser.vacation_days = currentUser.vacation_days - daysAmount;

      await makeRequest(updateUser.bind(null, currentUser._id, currentUser));
    }

    const updatedResponse = await makeRequest(
      updateDocumentTicket.bind(null, selectedTicket._id, payload)
    );

    if (!updatedResponse?.data) {
      addMessage("Something went wrong", "error");
      return rerenderTickets();
    }
  };

  const handleShowListeningInformation = async () => {
    setShowlisteningObjections(selectedTicket);

    const response = await makeRequest(
      getRecordingById.bind(
        null,
        selectedTicket.calling_information[0].stored_data
      )
    );

    if (response.data) {
      const blob = new Blob([new Uint8Array(response.data.data.data)], {
        type: "audio/mp3",
      });
      const url = URL.createObjectURL(blob);
      setRecording(() => url);
    }
  };

  const handleListenSuspicious = async () => {
    setIsAudioLoading(() => true);
    const response = await makeRequest(
      getFileById.bind(null, selectedTicket.suspicion.recording, true)
    );
    setIsAudioLoading(() => false);

    if (response.data) {
      await loadData();

      const blob = new Blob([new Uint8Array(response.data.data.data)], {
        type: "audio/mp3",
      });
      const url = URL.createObjectURL(blob);
      setAudio(() => url);
    }
  };

  const handleTranscript = async () => {
    setIsTranscriptionLoading(() => true);
    const response = await makeRequest(
      getTranscriptionById.bind(null, selectedTicket.suspicion.recording)
    );
    setIsTranscriptionLoading(() => false);

    if (response.data) {
      await loadData();

      setTranscription(() => response.data);
    }
  };

  const loadCompleteCallHistory = async () => {
    const response = await makeRequest(
      getCallHistory.bind(null, selectedTicket.contact)
    );

    if (!response?.data) {
      return addMessage("Couldn't load", "error");
    }
    return setCallHistoryTimeline(() => response.data);
  };

  const isCallHistoryLoading = hasUnfilledRequest(getCallHistory);

  return (
    <ActionsWrapper>
      {selectedTicket.type === "suspicion" &&
        selectedTicket?.status === "open" && (
          <>
            <Hover label="See complete call history">
              <ActionIcon
                onClick={() => loadCompleteCallHistory()}
                className={
                  isCallHistoryLoading
                    ? "fa fa-spinner fa-spin"
                    : "fa fa-folder-tree"
                }
                color={Colors.darkBlue}
              />
            </Hover>
            {(selectedTicket.suspicion_document.first_download_at ||
              selectedTicket.suspicion_document.first_transcription_at) && (
              <Hover label="Make responsible decision">
                <ActionIcon
                  onClick={() => setIsDeciding(true)}
                  className="fa fa-gavel"
                  color={Colors.darkBlue}
                />
              </Hover>
            )}
            <Hover label="Listen">
              <ActionIcon
                onClick={() => handleListenSuspicious()}
                className={
                  isAudioLoading ? "fa fa-spinner fa-spin" : "fa fa-volume-low"
                }
                color={Colors.darkBlue}
              />
            </Hover>
            <Hover label="Transcript">
              <ActionIcon
                onClick={() => handleTranscript()}
                className={
                  isTranscriptionLoading
                    ? "fa fa-spinner fa-spin"
                    : "fa fa-scroll"
                }
                color={Colors.darkBlue}
              />
            </Hover>
          </>
        )}
      {selectedTicket.contact && (
        <>
          <LabelWrapper>
            <ActionIcon
              className="fa fa-envelope"
              color="grey"
              onClick={() => {
                copyLinkToClipboard(selectedTicket._contact.email);
                addMessage("Copied", "success", 1000);
              }}
            />
            <Label>Copy email</Label>
          </LabelWrapper>
          <LabelWrapper>
            <ActionIcon
              rotate
              className="fa fa-address-book"
              color="grey"
              onClick={() => handleNavigateToContact()}
            />
            <Label>Contact info</Label>
          </LabelWrapper>
        </>
      )}
      {selectedTicket.type === "question" && !selectedTicket.contact && (
        <LabelWrapper>
          <div
            style={{ position: "relative" }}
            onClick={() => setIsAddContactOpen(() => true)}
          >
            <ActionIcon rotate className="fa fa-address-book" color="grey" />
            <ContactAddIconWrapper>
              <i
                className={
                  isAttachmentLoading
                    ? "fa-solid fa-spinner fa-spin"
                    : "fa fa-plus"
                }
              />
            </ContactAddIconWrapper>
          </div>
          <Label>Add contact</Label>
        </LabelWrapper>
      )}
      {selectedTicket.order && (
        <LabelWrapper>
          <ActionIcon
            className="fa fa-truck-fast"
            color="grey"
            onClick={() => handleNavigateToOrder()}
          />
          <Label>Order info</Label>
        </LabelWrapper>
      )}
      {selectedTicket.type === "document" &&
        !OLD_STATUSES.includes(selectedTicket.status) && (
          <>
            <Hover label="To correct">
              <ActionIcon
                onClick={() => setIsToCorrectOpen(true)}
                className="fa fa-edit"
                color={Colors.darkBlue}
              />
            </Hover>
            <Hover label="Decline">
              <ActionIcon
                onClick={() => setIsToDeclineOpen(true)}
                className="fa fa-ban"
                color={Colors.red}
              />
            </Hover>
            <Hover label="Accept">
              <ActionIcon
                onClick={() => handleAcceptDocument()}
                className="fa fa-check"
                color={Colors.green}
              />
            </Hover>
          </>
        )}
      {selectedTicket?.notification?.call_center_candidate && (
        <Hover label="Navigate to candidate">
          <ActionIcon
            onClick={() => handleNavigateToCandidate()}
            className="fa fa-user"
            color={Colors.darkBlue}
          />
        </Hover>
      )}
      {!NOT_ABLE_TO_CLOSE.includes(selectedTicket.type) &&
        !OLD_STATUSES.includes(selectedTicket.status) && (
          <Hover label="Close ticket">
            <ActionIcon
              className={"fa fa-trash animation-scale"}
              color={Colors.red}
              onClick={(e) => handleCloseTicket()}
            />
          </Hover>
        )}
      {CALLING_TICKETS.includes(selectedTicket.type) && (
        <Hover label="Listen">
          <ActionIcon
            className="fa  fa-circle-info animation-scale"
            color={Colors.darkBlue}
            onClick={() => handleShowListeningInformation()}
          />
        </Hover>
      )}
      {isAddContactOpen && (
        <AddContact
          selectedTicket={selectedTicket}
          loadData={loadData}
          setIsAttachmentLoading={setIsAttachmentLoading}
          setIsAddContactOpen={setIsAddContactOpen}
        />
      )}
      {audio && (
        <MediaPlayer recording={audio} setClose={setAudio} noDownload={true} />
      )}
      {transcription && (
        <Transcriptions
          transcriptions={transcription}
          setClose={setTranscription}
        />
      )}
      {isDeciding && (
        <MakeDecision
          reloadFn={loadData}
          ticket={selectedTicket}
          setShow={setIsDeciding}
        />
      )}
      {callHistoryTimeline && (
        <CallHistoryTimeline
          currentSuspicionRecording={selectedTicket.suspicion.recording}
          handleCloseTicketView={handleCloseTicketView}
          setShow={setCallHistoryTimeline}
          callHistoryTimeline={callHistoryTimeline}
          setAudio={setAudio}
          setTranscription={setTranscription}
          loadData={loadData}
        />
      )}
    </ActionsWrapper>
  );
};

export default ConversationActoins;
