import moment from "moment";
import React from "react";
import styled from "styled-components";
import { calcBySeconds } from "../../../../common/functions/hoursFormat";

const THead = styled.thead``;

const TBody = styled.tbody``;

const CustomWrapper = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 20px;
`;

const WorkingData = ({ workingData }) => {
  const getColor = (status) => {
    if (status === "login") {
      return "#C7F8A0";
    }

    if (status === "break") {
      return "#F0BD5A";
    }

    if (status === "logout") {
      return "#D9D9D9";
    }

    return "#96E35A";
  };

  return (
    <table className="styled-table">
      <THead>
        <tr>
          <th colSpan={8}>
            Working stats Users{" "}
            {workingData[0]
              ? "for " + moment(workingData[0].login_at).format("MM/DD")
              : ""}
          </th>
        </tr>
        <tr>
          <th>No.</th>
          <th>User</th>
          <th>Working</th>
          <th>Break</th>
          <th>Last Status</th>
          <th>Login</th>
          <th>Logout</th>
        </tr>
      </THead>
      <TBody className="queue">
        {workingData.length > 0 ? (
          workingData
            .sort(
              (a, b) =>
                new Date(b.login_at).getTime() - new Date(a.login_at).getTime()
            )
            .map((element, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{element.username}</td>
                <td>{calcBySeconds(element.working_seconds)}</td>
                <td>{calcBySeconds(element.break_seconds)}</td>
                <td>
                  <p
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <CustomWrapper
                      style={{
                        background: getColor(element.last_status),
                      }}
                    >
                      {element.last_status}
                    </CustomWrapper>{" "}
                    {(element.last_status_at &&
                      moment(element.last_status_at).format("HH:mm:ss")) ||
                      "------"}
                  </p>
                </td>
                <td>
                  {(element.login_at &&
                    moment(element.login_at).format("HH:mm:ss")) ||
                    "------"}
                </td>
                <td>
                  {(element.last_logout_at &&
                    moment(element.last_logout_at).format("HH:mm:ss")) ||
                    "------"}
                </td>
              </tr>
            ))
        ) : (
          <tr>
            <td colSpan={8}>There is no users</td>
          </tr>
        )}
      </TBody>
    </table>
  );
};

export default WorkingData;
